/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Row, Col,
  Button,
  Form,
  Input,
  Typography,
  Switch,
  Modal,
  Radio,
  Space,
} from 'antd';
import { PropTypes } from 'prop-types';

import { QuestionCircleFilled } from '@ant-design/icons';
import Topbar from '../../containers/Topbar/app';
import RegistrationProgress from '../../components/common/RegistrationProgress/RegistrationProgress';
import ProgressTitle from './ProgressTitle';
import { initializeCollection } from '../../redux/actions/Collection';
import InfoView from '../../components/common/InfoView';
import encryptStorage from '../../util/encryption';
import CancelButton from '../../components/Widget/CancelButton';
import { SAVE_PHONE_NUMBER, SET_PAYMENT_OPTION, UPDATE_USER_PHONE_NUMBER } from '../../constants/ActionTypes';
import FormCard from '../../components/common/FormCard';
import PayCard from '../../containers/common/PayCard';
import countryCodes from '../../util/country_code_with_currency.json';

const Momo = (props) => {
  const [savePhonenumber, setSavePhonenumber] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [addNumber, setAddNumber] = useState(false);
  const [phonenumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const { invoiceDetails } = useSelector(({ data }) => data);
  const accounts = useSelector(
    ({ auth }) => auth.authUser.accounts,
  );
  const phonenumbers = accounts.length > 0 && invoiceDetails.currency
    ? accounts.find((obj) => obj.currency === invoiceDetails.currency) : [];
  const { amount } = invoiceDetails;
  const dispatch = useDispatch();
  const allInput = amount !== 0 && phonenumber !== '';
  const token = encryptStorage.getItem('token');
  const { initializeCollectionCode } = useSelector(({ collection }) => collection);
  const { loading } = useSelector(({ common }) => common);
  useEffect(() => {
    document.title = 'Complete payment with mobile money';
  }, []);

  useEffect(() => {
    dispatch({ type: SET_PAYMENT_OPTION, payload: 'MOMO' });
  }, [dispatch]);

  useEffect(() => {
    if (invoiceDetails.currency) {
      const country = countryCodes.find(
        (c) => c.currency === invoiceDetails.currency,
      );
      setCountryCode(country);
    }
  }, [invoiceDetails.currency]);

  const { history } = props;

  const onFinish = (values) => {
    const collection = {
      customer_reference: 'auto',
      invoice_id: invoiceDetails.id,
      transaction_origin: 'APP',
      transaction_method: 'MOBILE_MONEY',
      currency: invoiceDetails.currency,
      amount: invoiceDetails.amount,
      provider_code: 'unknown',
      description: `QWAAP Wallet deposit for ${values.phonenumber}`,
      account_number: `${countryCode.calling_code}${values.phonenumber}`,
    };
    dispatch({ type: SAVE_PHONE_NUMBER, payload: savePhonenumber });

    if (phonenumbers === undefined
      || phonenumbers.length === 0 || !phonenumbers.account_numbers.some(
      (number) => number === `${countryCode.calling_code}${values.phonenumber}`,
    )
    ) {
      dispatch({
        type: UPDATE_USER_PHONE_NUMBER,
        payload: {
          currency: invoiceDetails.currency,
          phoneNumber: `${countryCode.calling_code}${values.phonenumber}`,
        },
      });
    }
    dispatch(initializeCollection(collection, token));
  };

  useEffect(() => {
    if (initializeCollectionCode !== null) {
      if (initializeCollectionCode === 200) {
        history.push('/confirm');
      }
    }
  }, [initializeCollectionCode, history]);

  useEffect(() => {
    if (phonenumbers && phonenumbers.account_numbers > 0) {
      setPhoneNumber(phonenumbers.account_numbers[0]);
    }
  }, [phonenumbers]);

  const formatPhoneNumber = (e) => {
    const value = e.replace(/\D/g, '').substring(0, Number(countryCode.phonenumber_length));
    return value;
  };

  const handleModal = () => {
    setShowModal(true);
  };

  return (
    <div className="gx-app-layout-">
      <Topbar />

      <div
        className="complete-registration-content-wrapper"
        style={{ margin: '2% auto' }}
      >
        <ProgressTitle title="Pay with Mobile Money" />

        <RegistrationProgress
          first="complete"
          second="active"
          third=""
          fourth=""
        />
        <Row gutter={24} className="gx-mx-0">
          <Col xl={8} lg={12} md={16} sm={24} xs={24} className="gx-mx-auto gx-my-5">

            <PayCard
              amount={invoiceDetails.amount}
              currency={invoiceDetails.currency}
            />
            <br />

            <Form
              initialValues={{ remember: true }}
              className="gx-signin-form gx-form-row-0"
              onFinish={onFinish}
            >
              <FormCard>
                { !addNumber && phonenumbers && phonenumbers.account_numbers ? (
                  <>
                    <div className="h5 gx-font-weight-bold gx-mb-2">Select your number</div>
                    <Form.Item
                      name="phonenumber"
                      className="gx-mb-0 gx-mx-0"
                      rules={[{
                        required: true,
                        message: 'Please select a number',
                      }]}
                    >

                      <Radio.Group
                        onChange={(e) => setPhoneNumber(e.target.value.replace(countryCode.calling_code, ''))}
                        value={phonenumber}
                      >
                        <Space direction="vertical">
                          {phonenumbers.account_numbers.map((phone) => (
                            <Radio key={phone} value={phone.replace(countryCode.calling_code, '')}>{phone}</Radio>
                          ))}
                        </Space>
                      </Radio.Group>

                    </Form.Item>
                    <div className="gx-mt-1 gx-text-right"><Button type="primary" ghost size="medium" onClick={() => setAddNumber(!addNumber)}>Use another number</Button></div>

                  </>
                )
                  : (
                    <>
                      <Form.Item style={{ marginBottom: 0, fontWeight: 600 }} className="gx-mx-0">
                        <Typography.Text>Enter mobile money phone number</Typography.Text>
                      </Form.Item>

                      <Form.Item
                        className="gx-mb-0 gx-mx-0"
                        name="phonenumber"
                        initialValue={phonenumber}
                        onChange={(e) => setPhoneNumber(countryCode.calling_code + e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: 'Phone number is required',
                          },
                          {
                            min: Number(countryCode.phonenumber_length),
                            message: 'Incomplete phone number',
                          },
                        ]}
                        getValueFromEvent={(e) => formatPhoneNumber(e.target.value)}
                        hasFeedback
                      >

                        <Input placeholder="Enter your phone number here" size="large" prefix={countryCode.calling_code} style={{ borderColor: '#bbb' }} />
                      </Form.Item>

                      <div className="h6 gx-text-muted gx-mt-2" style={{ margin: '0 0 20px' }}>Use international format. e.g 256772000123</div>
                      <Form.Item className="gx-mx-0">
                        <div className="gx-mb-2">
                          Save phone number
                          {' '}
                          <QuestionCircleFilled className="gx-text-primary" onClick={handleModal} />
                        </div>
                        <Switch
                          checked={savePhonenumber}
                          onChange={() => setSavePhonenumber(!savePhonenumber)}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                        />
                      </Form.Item>
                      {phonenumbers && phonenumbers.account_numbers && (<div className="gx-mt-1 gx-text-right"><Button type="primary" ghost size="medium" onClick={() => setAddNumber(!addNumber)}>Use a saved number</Button></div>)}
                    </>
                  )}
              </FormCard>

              <Form.Item className="gx-mx-0">
                <div style={{ display: 'flex' }}>
                  <Button type="primary" ghost onClick={() => history.go(-1)}>
                    Back
                  </Button>
                  <div className="gx-ml-auto" />
                  <CancelButton />
                  <Button
                    type="primary"
                    className={`gx-mb-0 ${allInput && !loading ? 'enabled' : ' disabled'}`}
                    htmlType={`${allInput ? 'submit' : 'button'}`}
                    loading={loading}
                  >
                    Continue
                  </Button>
                </div>

              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Modal
          centered
          visible={showModal}
          footer={null}
          onCancel={() => setShowModal(false)}
        >
          <h4><b>Save Phone Number</b></h4>
          <p>
            Save time by saving your phone number.
            We save your phone number for future use so you
            do not have to enter it everytime you use this payment option.
          </p>
          <div className="gx-mt-3 gx-text-right">
            <Button type="primary" onClick={() => setShowModal(false)}>OK</Button>
          </div>
        </Modal>

        <InfoView showLoader={false} />
      </div>
    </div>
  );
};

Momo.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
  }).isRequired,
};

export default Momo;
