/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Button,
  Form,
  Typography,
  Row,
  Col,
} from 'antd';
import Topbar from '../../containers/Topbar/app';
import RegistrationProgress from '../../components/common/RegistrationProgress/RegistrationProgress';
import { initializeCollection } from '../../redux/actions/Collection';
import encryptStorage from '../../util/encryption';
import { getPaymentOptions } from '../../redux/actions/Data';
import {
  SET_PAYMENT_OPTION,
} from '../../constants/ActionTypes';
import FormCard from '../../components/common/FormCard';
import InfoView from '../../components/common/InfoView';
import PayCard from '../../containers/common/PayCard';
import CancelButton from '../../components/Widget/CancelButton';
import { stripTrailingSlash } from '../../util/helpers';

const Crypto = (props) => {
  const { invoiceDetails } = useSelector(({ data }) => data);
  const { getProviderDetails } = useSelector(({ data }) => data);
  const { amount } = invoiceDetails;
  const dispatch = useDispatch();
  const { currency } = invoiceDetails;
  const option = 'CRYPTO';
  const allInput = amount !== 0;
  const { history } = props;
  const token = encryptStorage.getItem('token');
  const { initializeCollectionCode } = useSelector(({ collection }) => collection);
  const baseUrl = (process.env.REACT_APP_BASE_URL).toString();

  useEffect(() => {
    document.title = 'Complete payment with crypto';
  }, []);

  // Sets payment option
  useEffect(() => {
    dispatch({ type: SET_PAYMENT_OPTION, payload: 'CRYPTO' });
  }, [dispatch]);

  // @returns payment options for a given currency
  useEffect(() => {
    if (token && currency) {
      dispatch(getPaymentOptions(token, currency, option));
    }
  }, [dispatch, currency, token, option]);

  const onFinish = () => {
    const collection = {
      customer_reference: 'auto',
      invoice_id: invoiceDetails.id,
      transaction_origin: 'APP',
      transaction_method: 'CRYPTO',
      currency: invoiceDetails.currency,
      amount: invoiceDetails.amount,
      provider_code: getProviderDetails.provider_code,
      description: `QWAAP Wallet deposit for ${invoiceDetails.id}`,
      redirect_url: `${stripTrailingSlash(baseUrl)}/check-status`,
    };

    dispatch(initializeCollection(collection, token));
  };

  useEffect(() => {
    if (initializeCollectionCode !== null) {
      if (initializeCollectionCode === 200) {
        history.push('/confirm');
      }
    }
  });

  return (
    <div>
      <Topbar />
      <div style={{ margin: '3% auto' }}>
        <Typography.Title
          level={4}
          style={{ textAlign: 'center', fontWeight: 400 }}
        >
          Crypto Payment
        </Typography.Title>

        <RegistrationProgress
          first="complete"
          second="active"
          third=""
          fourth=""
        />

        <Row gutter={24} className="gx-mx-0">
          <Col xl={8} lg={12} md={16} sm={24} xs={24} className="gx-mx-auto gx-my-5">
            <PayCard
              amount={(invoiceDetails.amount).toString()}
              currency={(invoiceDetails.currency).toString()}
            />
            <br />

            <Form
              initialValues={{ remember: true }}
              name="fundbybank"
              className="gx-signin-form gx-form-row-0"
              onFinish={onFinish}
            >
              <FormCard>
                Continue for instructions on how to complete your crypto transaction.
              </FormCard>

              <Form.Item className="gx-mx-0 gx-my-0">
                <div className="gx-flex-row">
                  <Button type="primary" ghost onClick={() => history.go(-1)}>
                    Back
                  </Button>
                  <div className="gx-ml-auto" />
                  <CancelButton />
                  <Button
                    type="primary"
                    className={`gx-mb-0 ${allInput ? 'enabled' : ' disabled'}`}
                    htmlType={`${allInput ? 'submit' : 'button'}`}
                  >
                    Continue
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <InfoView />
      </div>
    </div>
  );
};

Crypto.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
  }).isRequired,
};

export default Crypto;
