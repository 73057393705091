import React from 'react';
import { Layout } from 'antd';
import UserInfo from 'components/common/UserInfo';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import Logo from '../../components/common/Logos';
import ReturnToMerchantButton from '../../components/Widget/ReturnToMerchantButton';

const { Header } = Layout;

const Topbar = (props) => {
  const { invoiceDetails } = useSelector(({ data }) => data);
  const { centered } = props;
  return (
    <Header
      style={{
        boxShadow: 'none',
        zIndex: 100,
      }}
    >
      <Logo style={{ margin: centered ? '0 auto' : 0 }} />

      {!centered ? (
        <div className="gx-header-notifications gx-ml-auto gx-justify-content-center gx-align-items-center gx-flex-row">
          {invoiceDetails.redirect_url && (<ReturnToMerchantButton />)}
          <UserInfo />
        </div>
      ) : null }

    </Header>
  );
};
Topbar.defaultProps = {
  centered: true,
};

Topbar.propTypes = {
  centered: PropTypes.bool,
};
export default Topbar;
