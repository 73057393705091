import React from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';

const ReturnToMerchantButton = () => {
  const { invoiceDetails } = useSelector(({ data }) => data);

  const handleButtonClick = () => {
    window.location.href = invoiceDetails.redirect_url;
  };

  return (
    <Button type="primary" size="medium" ghost onClick={handleButtonClick}>Return to merchant</Button>
  );
};

export default ReturnToMerchantButton;
