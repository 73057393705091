import React from 'react';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RESET_COLLECTION_INITIALIZATION } from '../../constants/ActionTypes';

const CancelButton = () => {
  const { confirm } = Modal;
  const history = useHistory();
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    confirm({
      title: 'Are you sure you want to cancel this transaction?',
      centered: true,
      onOk() {
        dispatch({ type: RESET_COLLECTION_INITIALIZATION, payload: null });
        history.push('/options');
      },
      okText: 'Yes',
      onCancel() {
      },
      cancelText: 'No',
    });
  };
  return (
    <Button type="danger" onClick={handleButtonClick} ghost>Cancel</Button>
  );
};

export default CancelButton;
