import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useIdleTimer } from 'react-idle-timer';

import 'assets/vendors/style';
import 'styles/main.less';
import 'styles/components/Auth.less';
import store, { history } from './redux/store';
import App from './containers/App/index';
import encryptStorage from './util/encryption';

const NextApp = () => {
  const handleOnIdle = () => {
    encryptStorage.clear();
    localStorage.clear();
    if (history.location.path !== '/signin') {
      window.location.href = '/signin';
    }
  };

  // Logs the user out after 45 minutes
  const timeoutDuration = 1000 * 60 * 45;
  useEffect(() => {
    // Start the timer on component mount
    const logoutTimer = setTimeout(handleOnIdle, timeoutDuration);
    return () => {
      // Clear the timer on component unmount
      clearTimeout(logoutTimer);
    };
  }, [timeoutDuration]);

  // Logs out user after 20 minutes inactivity
  useIdleTimer({
    timeout: 1000 * 60 * 20,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
};

export default Sentry.withProfiler(NextApp);
