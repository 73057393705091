/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import axios from 'util/Api';
import {
  GET_TRANSACTION_STATUS,
  GET_PAYMENT_PROVIDERS,
  GET_INVOICE_DETAILS,
} from 'constants/ActionTypes';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';

// GET INVOICE DETAILS
export const getInvoiceDetails = (invoiceId) => (dispatch) => {
  dispatch({ type: FETCH_START });
  axios.get(`/invoice-details/${invoiceId}`)
    .then(({ data }) => {
      if (data.code === 200) {
        dispatch({ type: GET_INVOICE_DETAILS, payload: data.data });
        dispatch({ type: FETCH_SUCCESS });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: FETCH_SUCCESS });
      }
    }).catch((error) => {
      if (error.response && error.response.data.message) {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
      } else {
        const message = 'We were unable to get the details for your invoice. Please try again';
        dispatch({ type: FETCH_ERROR, payload: message });
      }
      console.error('Error: ', error.message);
    });
};

// GET TRANSACTION STATUS
export const getTransactionStatus = (reference, token) => (dispatch) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  axios.get(`/transactions/status-check/${reference}`, { headers })
    .then(({ data }) => {
      if (data?.code === 200) {
        dispatch({
          type: GET_TRANSACTION_STATUS,
          payload: { code: data.code, details: data.data },
        });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    }).catch((error) => {
      if (error.response && error.response.data.message) {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
      } else {
        const message = 'We were unable to get the final status of your transaction';
        dispatch({ type: FETCH_ERROR, payload: message });
      }
      console.error('Error: ', error.message);
    });
};

// GET PAYMENT OPTIONS
export const getPaymentOptions = (token, currency, method) => (dispatch) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const params = {
    currency,
    transaction_type: 'COLLECTION',
  };

  axios.get('/data/payment-providers/', { params, headers }).then(({ data }) => {
    if (data.code === 200) {
      const providerDetails = data.data.payment_providers;
      const details = providerDetails.find((provider) => provider.transaction_method === method);
      dispatch({
        type: GET_PAYMENT_PROVIDERS,
        payload: { code: data.code, data: details },
      });
    } else {
      dispatch({ type: FETCH_ERROR, payload: data.message });
    }
  }).catch((error) => {
    if (error.response && error.response.data.message) {
      dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
    } else {
      const message = 'We were unable to get a provider for your transaction. Please try again';
      dispatch({ type: FETCH_ERROR, payload: message });
    }
    console.error('Error: ', error.message);
  });
};
