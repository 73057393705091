/* eslint-disable import/prefer-default-export */
import axios from 'util/Api';
import {
  FETCH_WALLET_TRANSACTIONS_INIT,
  FETCH_WALLET_TRANSACTIONS_SUCCESS,
  FETCH_WALLET_TRANSACTIONS_ERROR,
  FETCH_RECENT_TRANSACTIONS_INIT,
  FETCH_RECENT_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_INIT,
  FETCH_TRANSACTIONS_SUCCESS,
  SHOW_ERROR_MESSAGE,
  FETCH_ERROR,
  SET_TRANSACTIONS_PAGE_SIZE,
} from 'constants/ActionTypes';
import formatDate from '../../util/helpers';
import encryptStorage from '../../util/encryption';

export const getWalletTransactions = (walletId, params = '') => (dispatch) => {
  dispatch({ type: FETCH_WALLET_TRANSACTIONS_INIT });
  axios
    .get(`/walletTransactions/wallet/${walletId}${params ? `?${params}` : ''}`)
    .then((response) => {
      const headers = response.headers['content-range'];
      let currentPage = 1;
      let totalRecords = 10;
      if (headers) {
        currentPage = Number(headers.split('-')[0]);
        totalRecords = Number(headers.split('/')[1]);
      }
      const payload = { data: response.data, currentPage, totalRecords };
      dispatch({ type: FETCH_WALLET_TRANSACTIONS_SUCCESS, payload });
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: error.response.data.message,
        });
      }
      dispatch({ type: FETCH_WALLET_TRANSACTIONS_ERROR });
    });
};

export const setTransactionsPageSize = (limit) => (dispatch) => {
  dispatch({ type: SET_TRANSACTIONS_PAGE_SIZE, limit });
};

export const getUserTransactions = (params = '', recent = false) => (
  dispatch,
) => {
  if (recent) {
    dispatch({ type: FETCH_RECENT_TRANSACTIONS_INIT, params });
  } else {
    dispatch({ type: FETCH_TRANSACTIONS_INIT, params });
  }

  const token = encryptStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  axios.get('/transactions/list', { headers }).then(({ data }) => {
    if (data?.code === 200) {
      const { transactions } = data.data;
      // console.log(transactions);
      const transactionData = transactions.map((transaction) => {
        let charge = transaction.transaction_charge;

        if (transaction.merchant_covers_all_charges === 'Y') {
          charge = 0;
        }

        const userTransactions = {
          _id: transaction.id,
          request_amount: transaction.invoice_amount,
          request_currency: transaction.transaction_currency,
          transaction_amount: transaction.invoice_amount,
          transaction_currency: transaction.transaction_currency,
          transaction_fee: charge,
          provider_code: transaction.transaction_method,
          institution_name: transaction.provider_name,
          created_at: formatDate(transaction.transaction_date),
          internal_reference: transaction.internal_reference,
          merchant_reference: transaction.merchant_reference,
          transaction_type: transaction.transaction_type,
          transaction_status: transaction.status,
          invoice_status: transaction.invoice_payment_status,
          invoice_number: transaction.invoice_number,
          invoice_amount: transaction.invoice_amount,
          account_number: transaction.account_number,
          message: transaction.status_message,
          narration: '',
        };

        return userTransactions;
      });

      const payload = { data: transactionData, currentPage: 1, totalRecords: 1 };
      if (recent) {
        dispatch({ type: FETCH_RECENT_TRANSACTIONS_SUCCESS, payload });
      } else {
        dispatch({ type: FETCH_TRANSACTIONS_SUCCESS, payload });
      }
      // console.log(data);
    } else {
      // console.log(data);
    }
  }).catch((error) => {
    if (error.response && error.response.data.message) {
      const payload = {};
      dispatch({ type: FETCH_RECENT_TRANSACTIONS_SUCCESS, payload });
      dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
    } else {
      const message = 'We were unable to get your transaction hostory. Please try again';
      dispatch({ type: FETCH_ERROR, payload: message });
    }
    console.error('Error: ', error.message);
  });
};
