import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Button, Form, Typography } from 'antd';
import { expressLogin } from 'redux/actions/Auth';
import { showErrorMessage } from 'redux/actions/Common';
import AuthCardFormContainer from '../common/AuthCardFormContainer';
import OTPInput from '../../components/common/OTPInput';
import InfoView from '../../components/common/InfoView';
import { RETURN_TO_EXPRESS_LOGIN, SET_COUNT } from '../../constants/ActionTypes';
import { requestExpressLoginOtp } from '../../redux/actions/Auth';

const OTP = (props) => {
  const [inputCode, setInputCode] = useState('');
  const { count } = useSelector(({ common }) => common);
  const [time, setTime] = useState(count || 90);
  const dispatch = useDispatch();
  const {
    expressLoginCode, authUser, token,
  } = useSelector(({ auth }) => auth);
  const { loading } = useSelector(({ common }) => common);
  const { history } = props;

  const handleVerification = () => {
    if (inputCode && inputCode.length === 6) {
      dispatch(expressLogin(authUser.email, inputCode.trim()));
    } else {
      const message = 'Input Code must be 6 characters';
      dispatch(showErrorMessage(message));
    }
  };

  const handleCodeChange = (code) => {
    setInputCode(code);
  };

  const handleCodeRegeneration = () => {
    dispatch(requestExpressLoginOtp(authUser.email));
    setTime(90);
  };

  useEffect(() => {
    if (authUser === undefined) {
      history.push('/signin');
    }
  });

  const handleGoBack = () => {
    dispatch({ type: RETURN_TO_EXPRESS_LOGIN });
    history.push('/');
  };

  

  useEffect(() => {
    const timer = time > 0
      && setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

    return () => {
      clearInterval(timer);
      dispatch({ type: SET_COUNT, payload: time });
    };
  }, [time, dispatch]);

  const formatTime = (t) => {
    const minutes = Math.floor(t / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    if (expressLoginCode !== null) {
      if (expressLoginCode === 200 && token !== null) {
        history.push('/options');
      }
    }
  }, [history, expressLoginCode, token]);

  useEffect(() => {
    document.title = 'Enter OTP';
  }, []);

  return (
    <>
      <AuthCardFormContainer>
        <div className="gx-text-center gx-my-4">
          <Typography.Title level={5}>Enter OTP</Typography.Title>
        </div>
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish=""
          className="gx-signin-form gx-form-row0"
        >
          <div className="gx-text-center">
            <Form.Item>
              OTP code has been sent to
              <br />
              {' '}
              <strong className="gx-text-primary">{authUser.email}</strong>
              <br />
              Enter it here.
            </Form.Item>

            <Form.Item>
              <OTPInput onChange={handleCodeChange} value={Number(inputCode)} />
            </Form.Item>

            <Form.Item>
              <p className="bold">                 
                {time ? ` Resend OTP in ${formatTime(time)} seconds.` : ''}
              </p>
              <Button type="link" onClick={handleCodeRegeneration} disabled={time ? true : false}>Resend OTP Code</Button>
            </Form.Item>
          </div>

          <Form.Item className="gx-mb-0">
            <div>
              <div style={{ textAlign: 'right', display: 'flex' }}>
                <Button
                  type="primary5"
                  htmlType="button"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  onClick={handleVerification}
                  style={{ marginLeft: 'auto' }}
                  className={`gx-mb-0 ${
                    inputCode.length === 6 && !loading ? 'enabled' : 'disabled'
                  }`}
                  htmlType={`${inputCode.length === 6 ? 'submit' : 'button'}`}
                  loading={loading}
                >
                  {loading ? 'Verifying' : 'Verify'}
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </AuthCardFormContainer>
      <InfoView showLoader={false} />
    </>
  );
};

OTP.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
  }).isRequired,
};

export default OTP;
