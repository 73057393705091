import axios from 'util/Api';
import {
  INITIATE_COLLECTION_START,
  INITIATE_COLLECTION_SUCCESS,
  INITIATE_COLLECTION_ERROR,
  CONFIRM_COLLECTION_ERROR,
  CONFIRM_COLLECTION_START,
  CONFIRM_COLLECTION_SUCCESS,
  SHOW_ERROR_MESSAGE,
  FETCH_START,
  FETCH_ERROR,
  FETCH_SUCCESS,
} from '../../constants/ActionTypes';

// INITIATE COLLECTION
export const initializeCollection = (collection, token) => (dispatch) => {
  dispatch({ type: INITIATE_COLLECTION_START });
  dispatch({ type: FETCH_START });

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  axios
    .post('/collections/initialize', collection, { headers })
    .then(({ data }) => {
      // console.log(data);
      if (data?.code === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: INITIATE_COLLECTION_SUCCESS,
          payload: { code: data.code, data: data.data },
        });
      } else {
        dispatch({ type: INITIATE_COLLECTION_ERROR, payload: data.message });
        dispatch({ type: SHOW_ERROR_MESSAGE, payload: data.message });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data.message) {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
      } else {
        const message = 'We were unable to initiate your deposit. Please try again';
        dispatch({ type: FETCH_ERROR, payload: message });
      }
      console.error('Error: ', error.message);
    });
};

// CONFIRM COLLECTION
export const confirmCollection = (params) => (dispatch) => {
  dispatch({ type: CONFIRM_COLLECTION_START });
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${params.token}`,
  };
  axios
    .post('/collections/confirm', {
      internal_reference: params.reference,
      card_cipher: params.cipher,
      save_transaction_utils: params.save_transaction_utils,
      extra_params: params.extra_params
    }, { headers })
    .then(({ data }) => {
      if (data.code === 202) {
        dispatch({
          type: CONFIRM_COLLECTION_SUCCESS,
          payload: { code: data.code, data: data.data, message: data.message },
        });
      } else {
        dispatch({ type: CONFIRM_COLLECTION_ERROR, payload: data.message });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data.message) {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
      } else {
        const message = 'We were unable to confirm your deposit. Please try again';
        dispatch({ type: FETCH_ERROR, payload: message });
      }
      console.error('Error: ', error.message);
    });
};
