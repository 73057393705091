/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Row, Col, Typography } from 'antd';

import { getInvoiceDetails } from '../../redux/actions/Data';
import Topbar from '../../containers/Topbar/app';
import RegistrationProgress from '../../components/common/RegistrationProgress/RegistrationProgress';
import ProgressTitle from './ProgressTitle';
import PaymentMethodCard from '../../components/common/PaymentMethodCard';
import InfoView from '../../components/common/InfoView';
import encryptStorage from '../../util/encryption';
import ReturnToMerchantButton from '../../components/Widget/ReturnToMerchantButton';
import LogoutButton from '../../components/Widget/LogoutButton';

const Options = () => {
  const dispatch = useDispatch();
  // const { history } = props;
  const { invoiceDetails, invoiceId } = useSelector(({ data }) => data);
  const token = String(encryptStorage.getItem('token'));

  const returnMethodObj = (method) => {
    const obj = {
      link: '',
      duration: '',
    };
    if (method === 'MOBILE_MONEY') {
      obj.link = '/momo';
      obj.duration = 'Instant';
    }

    if (method === 'CARD') {
      obj.link = '/card';
      obj.duration = 'Instant';
    }

    if (method === 'BANK') {
      obj.link = '/bank';
      obj.duration = 'Instant';
    }

    if (method === 'CRYPTO') {
      obj.link = '/crypto';
      obj.duration = 'Instant';
    }

    return obj;
  };

  useEffect(() => {
    if (token && invoiceId) {
      dispatch(getInvoiceDetails(invoiceId));
    }
  }, [dispatch, invoiceId, token]);

  useEffect(() => {
    document.title = 'Payment Options';
  }, []);

  return (
    <div className="gx-app-wrap">
      <Topbar />
      <div className="complete-registration-content-wrapper">
        <ProgressTitle title="Payment options" />

        <RegistrationProgress first="active" second="" third="" fourth="" />

        <Typography.Title
          level={5}
          className="gx-text-center gx-my-4"
          style={{ fontWeight: 400 }}
        >
          { invoiceId === null || invoiceDetails.payment_status === 'PAID' ? 'You have no pending invoices' : 'Select your preferred option to make the payment' }
        </Typography.Title>
        { invoiceId === null || invoiceDetails.payment_status === 'PAID' ? (<div className="gx-text-center"><Link to="/transactions">View previous transactions</Link></div>) : ''}

        {invoiceDetails.payment_status !== 'PAID'
          ? (
            <Row gutter={24} className="gx-mx-0">
              <Col xl={16} l={16} md={24} sm={24} xs={24} className="gx-mx-auto">
                <Row style={{ margin: 'auto' }} className="gx-justify-content-center">

                  {invoiceDetails.payment_options
                    ? (invoiceDetails.payment_options.map((option) => (
                      <Col xl={8} lg={8} md={16} sm={24} xs={24} key={option.method_name}>
                        <PaymentMethodCard
                          key={option.method}
                          channel={option.method_name}
                          type="Select"
                          link={returnMethodObj(option.method).link}
                          duration={returnMethodObj(option.method).duration}
                        />
                      </Col>
                    ))) : <></>}
                </Row>
              </Col>
            </Row>
          ) : null }
        <div className="gx-text-center gx-my-3">
          {invoiceDetails.redirect_url ? (
            <>
              <ReturnToMerchantButton />
              {' '}
              <LogoutButton />
            </>
          ) : <LogoutButton />}
        </div>
        <InfoView />
      </div>
    </div>
  );
};

Options.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
  }).isRequired,
};

export default Options;
