import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import createRootReducer from '../reducers';
import encryptStorage from '../../util/encryption';

// eslint-disable-next-line import/no-extraneous-dependencies
const { createBrowserHistory } = require('history');

export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const middleware = [thunk, routeMiddleware];

// only add logger in development (remove logs for production and tests)
if (process.env.REACT_APP_ENVIRONMENT === 'sandbox') {
  const logger = createLogger({
    collapsed: true,
  });
  middleware.push(logger);
}

let initialState = {};

try {
  initialState = encryptStorage.getItem('appState')
    ? JSON.parse(encryptStorage.getItem('appState'))
    : {};
} catch (error) {
  console.error('getError', error);
}

const saveState = (store) => (next) => (action) => {
  const saveStore = store.getState();
  encryptStorage.setItem('appState', JSON.stringify({ ...saveStore }));
  return next(action);
};

middleware.push(saveState);

// sentry
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    // Only add auth state to sentry
    const lastState = state.computedStates
      && state.computedStates[state.computedStates.length - 1].state;
    if (lastState) {
      const transformedState = {
        data: {
          user:
            lastState.auth && lastState.auth.authUser
              ? lastState.auth.authUser
              : {},
          url: lastState.auth && lastState.auth.initURL,
        },
      };
      return transformedState;
    }

    return {};
  },
});

function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      composeWithDevTools(
        applyMiddleware(routerMiddleware(history), ...middleware),
      ),
      sentryReduxEnhancer,
    ),
  );

  return store;
}

const store = configureStore(initialState);

export default store;
