import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import CircularProgress from 'components/common/CircularProgress';
import { message } from 'antd';

// import Auxiliary from 'util/Auxiliary';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from 'redux/actions/Common';

message.config({
  duration: 5,
  maxCount: 1,
});

function InfoView(props) {
  const { showLoader } = props;
  const dispatch = useDispatch();
  const error = useSelector(({ common }) => common.error);
  const loading = useSelector(({ common }) => common.loading);
  const infoMessage = useSelector(({ common }) => common.message);

  useEffect(() => {
    if ((infoMessage && !error) || (!infoMessage && error)) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 1200);
    }
  }, [error, infoMessage, dispatch]);

  return (
    <div>
      {loading && (
        <div className="gx-loader-view gx-loader-position">
          {showLoader && (<CircularProgress />)}
        </div>
      )}
      {error && message.error(<span id="message-id">{error}</span>)}
      {infoMessage && message.success(<span id="message-id">{infoMessage}</span>)}
    </div>
  );
}

InfoView.propTypes = {
  showLoader: PropTypes.bool,
};

InfoView.defaultProps = {
  showLoader: true,
};

export default InfoView;
