import {
  FETCH_STATS_START,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_ERROR,
  STATS_STATUS_CHANGED,
  STATS_CURRENCY_CHANGED,
} from 'constants/ActionTypes';

const INIT_STATE = {
  data: {},
  stats: {
    currency: 'UGX',
    currencies: [],
    status: 'COMPLETED',
    statuses: [],
    orders: 0,
    trades: 0,
    profits: 0,
  },
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_STATS_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_STATS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case FETCH_STATS_SUCCESS: {
      const data = action.payload;
      const statuses = data.orders_per_status.map((x) => x._id);
      const currencies = data.profits_per_currency.map((x) => x._id);

      const status = statuses.length > 0 ? statuses[0] : state.stats.status;
      const currency = currencies.length > 0 ? currencies[0] : state.stats.currency;

      const defaultValueOrders = data.orders_per_status.filter(
        (x) => x._id === status,
      );
      const orders = defaultValueOrders.length > 0 ? defaultValueOrders[0].total : 0;

      const defaultValueProfits = data.profits_per_currency.filter(
        (x) => x._id === currency,
      );
      const profits = defaultValueProfits.length > 0 ? defaultValueProfits[0].total : 0;

      return {
        ...state,
        loading: false,
        data: action.payload,
        stats: {
          ...state.stats,
          status,
          statuses,
          currency,
          currencies,
          orders,
          profits,
          trades: data.matched_trades || 0,
        },
      };
    }

    case STATS_STATUS_CHANGED: {
      const status = action.payload;
      const defaultValueOrders = state.data.orders_per_status.filter(
        (x) => x._id === status,
      );
      const orders = defaultValueOrders.length > 0 ? defaultValueOrders[0].total : 0;

      return {
        ...state,
        stats: {
          ...state.stats,
          status,
          orders,
        },
      };
    }

    case STATS_CURRENCY_CHANGED: {
      const currency = action.payload;
      const defaultValueProfits = state.data.profits_per_currency.filter(
        (x) => x._id === currency,
      );
      const profits = defaultValueProfits.length > 0 ? defaultValueProfits[0].total : 0;

      return {
        ...state,
        stats: {
          ...state.stats,
          currency,
          profits,
        },
      };
    }

    default:
      return state;
  }
};
