import React, { useEffect } from 'react';
import { Typography } from 'antd';
// import WalletsCardList from '../Wallets/WalletsCardList';
import RecentTransactions from '../Transactions/RecentTransactions';
import ResponsiveBodyContentWrap from '../../components/ResponsiveBodyContentWrap';

const { Title } = Typography;

const Dashboard = () => {
  useEffect(() => {
    document.title = 'Dashboard';
  });

  return (
    <ResponsiveBodyContentWrap>
      {/* <Title level={5}>Your Wallets</Title>
      <br />
      <WalletsCardList limit={3} />
      <br /> */}
      <Title level={5}>Recent Transactions</Title>
      <br />
      <RecentTransactions />
    </ResponsiveBodyContentWrap>
  );
};

export default Dashboard;
