import { EncryptStorage } from 'encrypt-storage';

// Example of secret_key variable in an .env file
const encryptStorage = new EncryptStorage(process.env.REACT_APP_SECRET_KEY, {
  prefix: process.env.REACT_APP_ENVIRONMENT,
  storageType: 'localStorage',
  stateManagementUse: true,
});

export default encryptStorage;
