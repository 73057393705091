import React from 'react';
import { PropTypes } from 'prop-types';
import { Avatar, Row, Col } from 'antd';
import {
  UploadOutlined,
  DownloadOutlined,
  SwapOutlined,
  StopOutlined,
  RedoOutlined,
} from '@ant-design/icons';

const RecentTransactionItem = ({ transaction }) => {
  let displaySign = '';
  let narrative = '';
  let icon;
  let badge = false;

  if (['DEPOSIT'].includes(transaction.transaction_type)) {
    displaySign = '+';
    narrative = 'Deposit from';
    icon = <UploadOutlined />;
  } else if (['WITHDRAW'].includes(transaction.transaction_type)) {
    displaySign = '-';
    narrative = 'Withdraw to';
    icon = <DownloadOutlined />;
  } else if (['TRANSFER'].includes(transaction.transaction_type)) {
    displaySign = '-';
    narrative = 'Transfer to';
    icon = <SwapOutlined />;
  }

  let statusColor = 'blue';
  if (transaction.transaction_status === 'COMPLETED') statusColor = 'green';
  if (transaction.transaction_status === 'FAILED') {
    statusColor = 'red';
    icon = <StopOutlined />;
    badge = true;
  }
  if (transaction.transaction_status === 'PENDING') {
    statusColor = 'orange';
    icon = <RedoOutlined />;
  }

  const avatarStyle = {
    fontSize: '15px',
    background: '#e9e9e9',
    color: '#6c757d',
    width: '40px',
    height: '40px',
    lineHeight: '40px',
  };

  const amountStyle = {
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.5',
    textAlign: 'right',
    color: `${statusColor}`,
  };

  const itemStyle = {
    // borderBottom: '1px solid #e1e1e1',
    padding: '1rem 1rem 1rem 0',
  };

  const badgeStyle = {
    display: 'block',
    width: '10px',
    height: '10px',
    borderRadius: '100%',
    position: 'absolute',
    backgroundColor: `${statusColor}`,
    bottom: '10px',
    right: '8px',
    zIndex: '40',
  };

  return (
    <>
      <div style={itemStyle}>
        <Row>
          <Col xl={2} lg={3} md={3} sm={3} xs={5}>
            <div
              className="gx-module-list-icon"
              style={{ position: 'relative', zIndex: '10' }}
            >
              <div className="gx-ml-2">
                <span className="ant-avatar ant-avatar-circle ant-avatar-image gx-size-40">
                  <Avatar
                    src={icon}
                    style={avatarStyle}
                    className="gx-avatar"
                    alt=""
                  />
                  {badge ? <span style={badgeStyle} /> : ''}
                </span>
              </div>
            </div>
          </Col>
          <Col xl={13} lg={9} md={10} sm={10} xs={10}>
            <div className="gx-mail-user-des">
              <span className="gx-sender-name">
                {narrative}
                {' '}
                <b>
                  {transaction.institution_name}
                  {' '}
                  {transaction.account_number}
                </b>
              </span>
              <div
                className="gx-time gx-text-muted"
                style={{ fontSize: '0.7rem' }}
              >
                {transaction.transaction_status}
              </div>
            </div>
          </Col>
          <Col xl={3} lg={4} md={4} sm={4} xs={9} />

          <Col xl={6} lg={8} md={7} sm={7} xs={24}>
            <div className="gx-message">
              <div className="gx-text-truncate" style={amountStyle}>
                {displaySign}
                {' '}
                {`${new Intl.NumberFormat().format(
                  transaction.transaction_amount,
                )}`}

                {' '}
                {transaction.transaction_currency}
              </div>
              <div
                className="gx-time gx-text-muted"
                style={{ fontSize: '0.8rem', textAlign: 'right' }}
              >
                {transaction.created_at}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

RecentTransactionItem.propTypes = {
  transaction: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default RecentTransactionItem;
