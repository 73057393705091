/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  // useSelector
} from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Button, Row, Col, Typography, Form,
} from 'antd';

import { showErrorMessage } from 'redux/actions/Common';
import encryptStorage from '../../util/encryption';
// import { userVerifyPhoneNumber } from '../../redux/actions/Auth';
// import { getCollectionProviders } from '../../redux/actions/Collection';
import Topbar from '../../containers/Topbar/app';
import RegistrationProgress from '../../components/common/RegistrationProgress/RegistrationProgress';
import ProgressTitle from './ProgressTitle';
import DepositFormCard from '../../components/common/FormCard';
import OTPInput from '../../components/common/OTPInput';

const VerifyPhoneNumber = (props) => {
  const [inputCode, setInputCode] = useState('');
  const [count, setCount] = useState(60);
  const dispatch = useDispatch();
  // const { phoneVerificationStatusCode } = useSelector(({ auth }) => auth);
  const { history } = props;
  // const currency = encryptStorage.getItem('initial_currency');
  const phoneNumber = encryptStorage.getItem('phoneNumber');
  // const hiddenPhoneNumber = phoneNumber(0, 3) + phoneNumber.slice(-3);

  const handleVerification = () => {
    if (inputCode && inputCode.length === 6) {
      // const { id } = jwt.decode(registrationToken, process.env.REACT_APP_JWT_DASHBOARD_SECRET);
      // dispatch(userVerifyPhoneNumber(phoneNumber, inputCode));
      // dispatch(getCollectionProviders(currency));
      history.push('/fund-account');
    } else {
      const message = 'Input Code must be 6 characters';
      dispatch(showErrorMessage(message));
    }
  };

  useEffect(() => {
    let seconds = Number(count);
    if (seconds === 0) return;
    const timer = setInterval(() => {
      seconds -= 1;
      setCount(seconds);
      if (seconds === 0) clearInterval(timer);
    }, 1000);
  }, [count]);

  // to be changed later
  // useEffect(() => {
  //   if (phoneVerificationStatusCode !== null) {
  //     if (phoneVerificationStatusCode === 200) {
  //       history.push('/fund-account');
  //     }
  //   }
  // }, [history, phoneVerificationStatusCode]);

  // generate new code
  // const handleRegenerateCode = () => {
  //   // definition
  // };

  const handleCodeRegeneration = () => {};

  useEffect(() => {
    document.title = 'Verify Phone Number';
  }, []);

  return (
    <div className="gx-app-wrap">
      <Topbar />
      <div className="complete-registration-content-wrapper">
        <ProgressTitle title="Verify Phone Number" />
        <RegistrationProgress
          first="complete"
          second="active"
          third=""
          fourth=""
        />

        <Row gutter={24} className="gx-mt-5 gx-mx-0">
          <Col span={8} style={{ margin: '0 auto' }}>
            <Form className="gx-signin-form gx-text-center ">
              <DepositFormCard>
                <Form.Item>
                  <Typography.Title level={5}>Input Code</Typography.Title>
                </Form.Item>

                <Form.Item>
                  <Typography.Paragraph>
                    A 6 digit code has been sent to the phone number
                    {' '}
                    {phoneNumber}
                    . Please check your SMS and enter the code
                    below.
                  </Typography.Paragraph>
                </Form.Item>

                <Form.Item>
                  <OTPInput
                    onChange={(code) => setInputCode(code)}
                    field={6}
                    value={inputCode}
                  />
                </Form.Item>

                <Form.Item>
                  <Typography.Paragraph>
                    Your code
                    {count !== 0
                      ? ` expires in ${count} seconds.`
                      : ' has expired.'}
                  </Typography.Paragraph>
                  <Button
                    type="link"
                    disabled={`${count !== 0}`}
                    onClick={handleCodeRegeneration}
                  >
                    Resend Code
                  </Button>
                </Form.Item>
              </DepositFormCard>
              <div style={{ display: 'flex' }}>
                <Button type="primary-5" onClick={() => history.go(-1)}>
                  Back
                </Button>

                <Button
                  type="primary"
                  onClick={handleVerification}
                  className={`${
                    inputCode.length === 6 ? 'enabled' : 'disabled'
                  }`}
                  htmlType={`${inputCode.length === 6 ? 'submit' : 'button'}`}
                  style={{ marginLeft: 'auto' }}
                >
                  Verify
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

VerifyPhoneNumber.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
  }).isRequired,
};

export default VerifyPhoneNumber;
