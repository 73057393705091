import React from 'react';
import { Button } from 'antd';
import { expressLogOut } from 'redux/actions/Auth';
import { useDispatch } from 'react-redux';

const LogoutButton = () => {
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    dispatch(expressLogOut());
  };

  return (
    <Button type="primary" size="medium" onClick={handleButtonClick}>Log out</Button>
  );
};

export default LogoutButton;
