import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { expressLogOut } from '../redux/actions/Auth';

const Signout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(expressLogOut());
  }, [dispatch]);
  return (
    <></>
  );
};

export default Signout;
