import React from 'react';
import { PropTypes } from 'prop-types';

const PayCard = (props) => {
  const {
    amount, currency,
  } = props;

  const paycardStyle = {
    padding: '10px',
    background: 'rgba(0,0,0,0.09)',
    borderRadius: '3px',
    display: 'flex',
    fontSize: '20px',
  };

  return (
    <>
      Pay
      <div style={paycardStyle} className="gx-flex gx-justify-content-center gx-mt-2">
        <div>{ new Intl.NumberFormat().format(amount)}</div>
        <div style={{ marginLeft: 'auto' }}>{currency}</div>
      </div>
      <br />

    </>
  );
};

PayCard.propTypes = {
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

export default PayCard;
