/* eslint-disable linebreak-style */
import React from 'react';
import { Typography, Row, Col } from 'antd';
import IntlMessages from '../../util/IntlMessages';

const Footer = () => {
  const { Paragraph } = Typography;
  const currentYear = new Date().getFullYear();
  const links = [
    {
      label: 'Privacy',
      href: 'https://dusupay.com/policies/',
    },
    {
      label: 'Terms',
      href: 'https://dusupay.com/terms-of-use/',
    },
    {
      label: 'Support',
      href: '//dusupay.com',
    },
  ];

  const listStyle = {
    display: 'inline-block',
    margin: '0 10px',
  };

  const linkStyle = {
    color: 'inherit',
  };

  return (
    <Row gutter={24} className="gx-mt-10 gx-text-center gx-mx-0">

      <Col xl={6} lg={6} md={12} sm={18} xs={18} className="gx-mx-auto">
        <div style={{ overflow: 'auto', marginBlock: 10 }}>
          <ul
            style={{
              listStyle: 'none',
              textAlign: 'center',
              fontWeight: 400,
              paddingLeft: 0,
            }}
          >
            {links.map((link) => (
              <li key={link.label} style={listStyle}>
                <a
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={linkStyle}
                >
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <Paragraph style={{ fontSize: '12px' }}>
          <IntlMessages id="footer.content" style={{ marginLeft: 20 }} />
        </Paragraph>

        <Paragraph style={{ fontSize: '12px', color: '#999' }}>
          &copy;
          {' '}
          {currentYear}
          {' '}
          <IntlMessages id="footer.copyright" />
        </Paragraph>
      </Col>

    </Row>
  );
};

export default Footer;
