import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CheckCircleFilled, LoadingOutlined, CloseCircleFilled } from '@ant-design/icons';
import {
  Button, Row, Col,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionStatus } from '../../redux/actions/Data';
import encryptStorage from '../../util/encryption';
import {
  FETCH_SUCCESS,
  RESET_TRANSACTION_COLLECTION_STATE,
  RESET_TRANSACTION_DATA_STATE,
} from '../../constants/ActionTypes';
import Topbar from '../../containers/Topbar/app';

const CheckStatus = () => {
  const { ref } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = encryptStorage.getItem('token');
  const [showProcessing] = useState(false);
  const [retry, setRetry] = useState(false);
  const [icon, setIcon] = useState(<LoadingOutlined style={{ color: 'green' }} />);
  const [instructions, setInstructions] = useState('');
  const [processingStatus, setProcessingStatus] = useState('Processing');
  const { error } = useSelector(({ common }) => common);
  const { transactionStatusDetails } = useSelector(({ data }) => data);

  const handleRetry = () => {
    dispatch({ type: RESET_TRANSACTION_COLLECTION_STATE });
    dispatch({ type: RESET_TRANSACTION_DATA_STATE });
    dispatch({ type: FETCH_SUCCESS });
    history.push('/options');
  };

  // check for the final status of the transaction
  useEffect(() => {
    // console.log(transactionStatusDetails.transaction)
    // console.log(ref)
    // dispatch(getTransactionStatus(ref, token));
    // if (showProcessing) {
    // const interval = setInterval(() => {
    dispatch(getTransactionStatus(ref, token));
    // }, 5000);
    // return () => clearInterval(interval);
    // }
    // return () => {};
  }, [token, ref, dispatch, showProcessing]);

  // Poll For Transaction Status
  useEffect(() => {
    if (error !== '') {
      setIcon(<CloseCircleFilled style={{ color: 'red' }} />);
      setProcessingStatus('Something went wrong');
      setInstructions(error);
      setRetry(true);
    }

    if (transactionStatusDetails.transaction) {
      if (transactionStatusDetails.transaction.status === 'FAILED') {
        setIcon(<CloseCircleFilled style={{ color: 'red' }} />);
        setProcessingStatus('Failed');
        setInstructions(transactionStatusDetails.transaction.status_message);
        setRetry(true);
      }

      if (transactionStatusDetails.transaction.status === 'COMPLETED') {
        setIcon(<CheckCircleFilled style={{ color: 'green' }} />);
        setProcessingStatus('Completed');
        setInstructions(
          <>
            {transactionStatusDetails.transaction.status_message}
            <div className="gx-mt-3">
              Redirecting
              {' '}
              <LoadingOutlined />
            </div>

          </>,
        );
        setTimeout(() => {
          dispatch({ type: RESET_TRANSACTION_COLLECTION_STATE });
          dispatch({ type: RESET_TRANSACTION_DATA_STATE });
          history.push('/transactions');
        }, 5000);
      }
    }
  }, [transactionStatusDetails, history, dispatch, error]);

  return (
    <div className="gx-app-wrap">
      <Topbar />
      <Row gutter={24} className="gx-align-items-center gx-justify-content-center" style={{ height: '60vh' }}>
        <Col span={8} className="gx-mx-auto gx-text-center">
          <div>
            {icon}
            {' '}
            {processingStatus}
          </div>

          <div style={{ padding: '10px 15px' }}>{instructions}</div>
          <br />
          {retry ? <div><Button onClick={handleRetry} type="primary" ghost>Try Again</Button></div> : null }
        </Col>
      </Row>

    </div>
  );
};

export default CheckStatus;
