import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Table, Input, Form, Select, Row, Col, Drawer,
} from 'antd';
import { jsPDF as JSpdf } from 'jspdf';
import 'jspdf-autotable';
import XLSX from 'xlsx';
import {
  getUserTransactions,
  setTransactionsPageSize,
} from '../../redux/actions/Transactions';
import { transactionColumns, searchableFields } from './Columns';
import DownloadBtns from '../../components/common/downloadbtns';
import logo from '../../assets/images/logo.png';
import Topbar from '../../containers/Topbar/app';
import TransactionDetailsForModal from './TransactionDetailsForModal';
import ResponsiveBodyContentWrap from '../../components/ResponsiveBodyContentWrap';
import ProgressTitle from '../Collections/ProgressTitle';
import ReturnToMerchantButton from '../../components/Widget/ReturnToMerchantButton';
import LogoutButton from '../../components/Widget/LogoutButton';
// import encryptStorage from '../../util/encryption';

const Transactions = () => {
  // const token = encryptStorage.getItem('token');
  const dispatch = useDispatch();
  const {
    data: userTransactions,
    loading,
    currentPage,
    pageSize,
    totalRecords,
  } = useSelector(({ transactions }) => transactions.userTransactions);
  const { invoiceDetails } = useSelector(({ data }) => data);
  // const transactions = userTransactions && userTransactions.length;
  const [searchParams, setSearchParams] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [visibleTransactionModal, setVisibilityTransactionModal] = useState(
    false,
  );

  useEffect(() => {
    // if (!transactions) {
    const defaultPagination = `sort={"created_at": -1}&page=${currentPage}&limit=${pageSize}`;
    dispatch(getUserTransactions(defaultPagination));
    // }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    document.title = 'Transactions History';
  }, []);

  const handleOnChange = (pagination, _, sort) => {
    if (pagination && sort) {
      const paginate = `page=${pagination.current}&limit=${pagination.pageSize}`;
      let sortParams = 'sort={"created_at": -1 }';
      if (Object.keys(sort).length && sort.field) {
        sortParams = `sort={"${sort.field}": ${
          sort.order === 'ascend' ? '1' : '-1'
        } }`;
      }
      let paginateAndSort = `${paginate}&${sortParams}`;
      if (searchParams) {
        paginateAndSort += `&filter={"$or": ${searchParams}}`;
      }
      dispatch(getUserTransactions(paginateAndSort));
    }
  };

  const handleSearch = (_searchValue) => {
    let params = searchableFields.map((field) => ({
      [field]: { $regex: _searchValue, $options: 'i' },
    }));
    if (!Number.isNaN(Number(_searchValue))) {
      params = [{ request_amount: Number(_searchValue) }];
    }
    if (moment(_searchValue, 'DD/MM/YYYY', true).isValid()) {
      const searchDate = moment(_searchValue, 'DD/MM/YYYY')
        .utc()
        .add(1, 'days')
        .toISOString();
      const after = moment(_searchValue, 'DD/MM/YYYY').utc().toISOString();
      const dateSearch = { created_at: { $gte: after, $lt: searchDate } };
      params = [dateSearch];
    }
    const searchParameters = JSON.stringify(params);
    const search = `filter={"$or": ${searchParameters}}&sort={"created_at": -1 }`;
    setSearchParams(searchParameters);
    setSearchValue(_searchValue);
    dispatch(getUserTransactions(search));
  };

  const viewTransactionDetails = (record) => ({
    onClick: () => {
      setSelectedTransaction(record);
      setVisibilityTransactionModal(true);
    },

  });

  const handleModalCancelTransactionModal = () => {
    setVisibilityTransactionModal(false);
    setSelectedTransaction({});
  };

  const props = {
    loading,
    pagination: {
      current: currentPage,
      pageSize,
      total: totalRecords,
      showSizeChanger: false,
    },
    onRow: viewTransactionDetails,
    rowKey: '_id',
    columns: transactionColumns,
    dataSource: userTransactions.map((e, i) => ({
      ...userTransactions[i],
      request_amount_str: `${new Intl.NumberFormat().format(
        userTransactions[i].request_amount,
      )} 
          ${userTransactions[i].request_currency}`,
      transaction_amount_str: `${new Intl.NumberFormat().format(
        userTransactions[i].transaction_amount,
      )} 
          ${userTransactions[i].transaction_currency}`,
      transaction_fee_str: `${new Intl.NumberFormat().format(
        userTransactions[i].transaction_fee || 0,
      )} 
          ${userTransactions[i].transaction_currency || ''}`,
      provider_str: `${userTransactions[i].provider_code || ''}
          (${userTransactions[i].institution_name || ''})`,
    })),
    onChange: handleOnChange,
  };

  const reportHeader = [
    'Date',
    'W/R',
    // 'M/R',
    'Type',
    'Request Amt',
    // 'Request Currency',
    'Transaction Amt',
    'Transaction Fee',
    'Transaction Currency',
    'Status',
    // 'Narration',
    // 'Wallet',
    'Institution',
    // 'Provider',
  ];

  function handlePdf(pdfData, file) {
    const time = moment(Date.now()).utc().format('DD-MM-YYYY');
    const doc = new JSpdf({ orientation: 'landscape' });
    doc.addImage(logo, 'PNG', 0, 0, 43.85, 17.06);
    doc.text(`${file} - ${time}`, 50, 10);
    const dataExtract = [];
    const datar = pdfData.dataSource;
    for (let i = 0; i < datar.length; i += 1) {
      dataExtract.push([
        datar[i].created_at,
        datar[i].internal_reference,
        // datar[i].merchant_reference,
        datar[i].transaction_type,
        datar[i].request_amount,
        // datar[i].request_currency,
        datar[i].transaction_amount,
        datar[i].transaction_fee,
        datar[i].transaction_currency,
        datar[i].transaction_status,
        datar[i].narration,
        // datar[i].wallet_currency_code,
        datar[i].institution_name,
        // datar[i].provider_code,
      ]);
    }
    doc.autoTable({
      margin: { top: 19, left: 1, right: 1 },
      head: [reportHeader],
      body: dataExtract,
    });
    doc.save(`${file} - ${time}.pdf`);
  }

  function handleXcl(xclData, file) {
    const time = moment(Date.now()).utc().format('DD-MM-YYYY');

    const datar = xclData.dataSource;
    const dataExtract = [reportHeader];
    for (let i = 0; i < datar.length; i += 1) {
      dataExtract.push([
        datar[i].created_at,
        datar[i].internal_reference,
        // datar[i].merchant_reference,
        datar[i].transaction_type,
        datar[i].request_amount,
        // datar[i].request_currency,
        datar[i].transaction_amount,
        datar[i].transaction_fee,
        datar[i].transaction_currency,
        datar[i].transaction_status,
        datar[i].narration,
        // datar[i].wallet_currency_code,
        datar[i].institution_name,
        // datar[i].provider_code,
      ]);
    }
    const workb = XLSX.utils.book_new();
    workb.SheetNames.push('Sheet1');
    const wsData = dataExtract;
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    workb.Sheets.Sheet1 = ws;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Responses');
    XLSX.writeFile(wb, `${file} - ${time}.xlsx`);
  }

  const handlePageLimitChange = (limit) => {
    dispatch(setTransactionsPageSize(limit || 10));
    handleSearch(searchValue);
  };

  const { Option } = Select;
  return (
    <div className="gx-app-wrap">
      <div className="gx-text-center gx-my-1">
        {invoiceDetails.redirect_url ? (
          <>
            <ReturnToMerchantButton />
            {' '}
            <LogoutButton />
          </>
        ) : <LogoutButton />}
      </div>
      <Topbar />
      <ResponsiveBodyContentWrap>
        <ProgressTitle title="Transactions" />
        <br />
        <br />

        <div className="justify-content-center">
          <Row>
            <Col xl={4} lg={4} md={4} sm={8} xs={8}>
              <Form name="basic" className="gx-form-row0">
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select currency"
                  value={`(${pageSize}/Page)`}
                  onChange={handlePageLimitChange}
                >
                  <Option value="10">(10/Page)</Option>
                  <Option value="50">(50/Page)</Option>
                  <Option value="100">(100/Page)</Option>
                  <Option value="200">(200/Page)</Option>
                  <Option value="500">(500/Page)</Option>
                  <Option value="1000">(1000/Page)</Option>
                </Select>
              </Form>
            </Col>
            <Col xl={10} lg={4} md={8} sm={16} xs={16}>
              <Input.Search
                placeholder="Search transactions"
                enterButton
                onSearch={handleSearch}
              />
            </Col>
            <Col xl={10} lg={14} md={12} sm={16} xs={24}>
              <DownloadBtns
                handlePdf={() => handlePdf({ ...props }, 'Transactions')}
                handleXcl={() => handleXcl({ ...props }, 'Transactions')}
              />
            </Col>

          </Row>
          <Table className="gx-table-responsive" {...props} />
        </div>
        <Drawer
          placement="right"
          closable
          onClose={handleModalCancelTransactionModal}
          visible={visibleTransactionModal}
          width={320}
        >
          <TransactionDetailsForModal {...{ selectedTransaction }} />
        </Drawer>
      </ResponsiveBodyContentWrap>
      <div className="gx-text-center gx-my-2">
        {invoiceDetails.redirect_url ? (
          <>
            <ReturnToMerchantButton />
            {' '}
            <LogoutButton />
          </>
        ) : <LogoutButton />}
      </div>
    </div>
  );
};

export default Transactions;
