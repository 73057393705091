import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import App from 'routes/index';
import Footer from '../../components/common/Footer';

const MainApp = () => {
  const match = useRouteMatch();
  return (
    <>
      <App match={match} />
      <Footer />
      <br />
    </>
  );
};
export default MainApp;
