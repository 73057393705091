/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { CheckCircleFilled, CloseCircleFilled, ReloadOutlined } from '@ant-design/icons';

const TransactionDetailsForModal = ({ selectedTransaction }) => {
  let data = { ...selectedTransaction };
  if (selectedTransaction && selectedTransaction.merchant_request_payload) {
    data = { ...selectedTransaction, merchant_request_payload: undefined };
  }
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    switch (data?.transaction_status) {
      case 'COMPLETED':
        setIcon(<CheckCircleFilled style={{ fontSize: '40px', color: '#07c572' }} />);
        break;
      case 'FAILED':
        setIcon(<CloseCircleFilled style={{ fontSize: '40px', color: '#ff0000' }} />);
        break;
      case 'PENDING':
        setIcon(<ReloadOutlined style={{ fontSize: '40px', color: 'grey' }} />);
        break;
      default:
        setIcon(null);
        break;
    }
  }, [data.transaction_status]);

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '70vh',
    }}
    >
      {data && data._id && (
        <div style={{ width: '100%', padding: '15px' }}>
          <div style={{ textAlign: 'center' }}>
            <div className="gx-my-4">
              {icon}
            </div>
            {data?.transaction_status}
            <br />
            <span>{data?.message}</span>
            <br />

            <span className="gx-text-grey">
              {moment(data?.created_at, 'ddd, MMM D, YYYY, HH:mm:ss Z').format('ddd, D MMM YY, hh:mm')}
            </span>

          </div>
          <div className="gx-mt-4" />
          <div style={{ display: 'flex' }}>
            <div>Transaction Amount:</div>
            <div style={{ marginLeft: 'auto' }}><strong>{data?.transaction_amount_str}</strong></div>
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <div>Transaction Fee:</div>
            <div style={{ marginLeft: 'auto' }}><strong>{data?.transaction_fee_str}</strong></div>
          </div>
          {/* {data?.request_currency !== data?.transaction_currency
            && data?.transaction_currency && (
              <Card>
                <div>
                  Conversion
                  {' '}
                  {data?.request_currency}
                  {' to '}
                  {data?.transaction_currency}
                </div>
                <div>
                  Rate Given
                  {data?.ex_rate_given}
                </div>
                <div>
                  Market Rate
                  {data?.ex_rate_market}
                </div>
              </Card>
            )} */}
        </div>
      )}
    </div>
  );
};

TransactionDetailsForModal.propTypes = {
  selectedTransaction: PropTypes.oneOfType([PropTypes.object]),
};

TransactionDetailsForModal.defaultProps = {
  selectedTransaction: {},
};
export default TransactionDetailsForModal;
