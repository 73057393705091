import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

const ResponsiveBodyContentWrap = ({ children }) => (
  <Row>
    <Col xl={3} lg={0} md={0} sm={0} xs={0} />
    <Col xl={18} lg={24} md={24} sm={24} xs={24}>
      {children}
    </Col>
    <Col xl={3} lg={0} md={0} sm={0} xs={0} />
  </Row>
);

export default ResponsiveBodyContentWrap;

ResponsiveBodyContentWrap.propTypes = {
  children: PropTypes.node.isRequired,
};
