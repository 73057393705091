/* eslint-disable linebreak-style */
import React from 'react';
import './style.css';
import { PropTypes } from 'prop-types';

function ProgramItem(props) {
  const { status, name } = props;
  return (
    <div className={`item ${status}`}>
      <div className="item-position gx-text-center">{name}</div>
      <div className="circle">
        <span />
      </div>
      <div className="label gx-text-primary gx-text-center">
        <small>{status === 'active' ? 'You are here' : ''}</small>
      </div>
    </div>
  );
}

ProgramItem.propTypes = {
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ProgramItem;
