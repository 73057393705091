import React from 'react';
import { Typography } from 'antd';
import { PropTypes } from 'prop-types';

const { Title } = Typography;

const titleStyle = {
  textAlign: 'center',
  fontWeight: 600,
  marginTop: '3%',
};

const ProgressTitle = (props) => {
  const { title } = props;
  return (
    <Title level={4} style={titleStyle}>
      {title}
    </Title>
  );
};

ProgressTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
export default ProgressTitle;
