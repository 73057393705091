import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import Data from './Data';
import Currencies from './Currencies';
import PaymentMethods from './PaymentMethods';
import Processors from './Processors';
import Providers from './Providers';
import Transactions from './Transactions';
import Stats from './Stats';
import ActivityLogs from './ActivityLogs';
import Collection from './Collection';
import { RESET_STATE } from '../../constants/ActionTypes';

const resettableReducer = (reducer) => (state, action) => {
  if (action.type === RESET_STATE) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

const resettableRootReducer = (history) => resettableReducer(combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  common: Common,
  collection: Collection,
  data: Data,
  transactions: Transactions,
  currencies: Currencies,
  paymentMethods: PaymentMethods,
  processors: Processors,
  providers: Providers,
  stats: Stats,
  activityLogs: ActivityLogs,
}));

export default resettableRootReducer;
