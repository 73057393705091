import React from 'react';

const HealthCheck = () => (
  <div>
    <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25%' }}>
      <h4>App is running and healthy</h4>
    </div>
  </div>
);

export default HealthCheck;
