import React from 'react';
import { Card, Button } from 'antd';
import { PropTypes } from 'prop-types';
import { history } from '../../redux/store';

function PaymentCard(props) {
  const {
    channel, type, link, duration,
  } = props;

  const handleClick = () => {
    history.push(link);
  };

  const cardStyle = {
    backgroundColor: '#f1f1f1',
  };

  return (
    <Card style={cardStyle} bordered={false}>
      <div>
        <div className="h4 gx-mb-2">{channel}</div>
        {/* <div className="gx-text-primary">some</div> */}
      </div>
      <br />
      <div className="footer gx-text-center gx-flex-row">
        <div className="gx-text-primary">
          {duration}
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Button
            type="primary"
            size="default"
            style={{ marginBottom: '-4px' }}
            onClick={handleClick}
            ghost
          >
            {type}
          </Button>
        </div>
      </div>
    </Card>
  );
}

PaymentCard.propTypes = {
  channel: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  link: PropTypes.string,
  duration: PropTypes.string,
};

PaymentCard.defaultProps = {
  link: '#',
  duration: '',
};

export default PaymentCard;
