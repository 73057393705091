import axios from 'axios';
import encryptStorage from './encryption';

const token = encryptStorage.getItem('token') || '';

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
};

const apiAxios = axios.create({
  baseURL: process.env.REACT_APP_API,
  ...headers,
});

const axiosInstances = [apiAxios];

axiosInstances.map((instance) => instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      encryptStorage.clear();
      window.location.href = '/signout';
    }

    return Promise.reject(error);
  },
));

export default apiAxios;
