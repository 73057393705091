import React from 'react';
import { PropTypes } from 'prop-types';
import ReactCodeInput from 'react-code-input';

function OTPInput(props) {
  const { onChange, value } = props;

  const input = {
    className: ReactCodeInput,
    inputStyle: {
      MozAppearance: 'textfield',
      width: '12%',
      borderRadius: '0',
      margin: '5px',
      fontSize: '16px',
      height: '40px',
      textAlign: 'center',
      border: '0',
      borderBottom: '2px solid #ccc',
      backgroundColor: '#f1f1f1',
    },
    inputStyleFocus: {
      border: '1px solid blue',
    },
    inputStyleInvalid: {
      margin: '4px',
      MozAppearance: 'textfield',
      width: '10%',
      borderRadius: '0px',
      fontSize: '14px',
      height: '40',
      paddingLeft: '7px',
      backgroundColor: '#f1f1f1',
      color: 'red',
      border: '1px solid red',
    },
  };

  return (
    <ReactCodeInput
      onChange={onChange}
      type="text"
      fields={6}
      value={value}
      {...input}
    />
  );
}

OTPInput.defaultProps = {
  value: null,
};

OTPInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default OTPInput;
