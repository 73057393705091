/* eslint-disable linebreak-style */
import React from 'react';
import { PropTypes } from 'prop-types';
import logo from '../../assets/images/logo.png';
import logoOutline from '../../assets/images/logo-outline.png';

const Logo = (props) => {
  const { type, style } = props;
  const alt = 'qwaap';
  let source = '';

  switch (type) {
    case 'outline':
      source = logoOutline;
      break;
    default:
      source = logo;
      break;
  }

  return (
    <div className="gx-app-logo gx-text-center" style={style}>
      <img src={source} alt={alt} />
    </div>
  );
};

Logo.propTypes = {
  type: PropTypes.string,
  style: PropTypes.shape({}),
};

Logo.defaultProps = {
  type: '',
  style: {},
};

export default Logo;
