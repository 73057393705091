import React from 'react';
import { Button } from 'antd';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SET_INVOICE_ID } from '../../constants/ActionTypes';

const RetryTransaction = (props) => {
  const { paymentStatus, transactionStatus, invoiceId } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRetry = () => {
    dispatch({ type: SET_INVOICE_ID, payload: invoiceId });
    history.push('/options');
  };

  // const paymentstatus = {
  //   marginTop: '10px',
  // };

  // const retrybutton = {
  //   backgroundColor: '#cc6600',
  //   color: '#fff',
  //   cursor: 'pointer',
  // };

  // const noactionbadge = {
  //   backgroundColor: '#4caf50',
  //   color: '#fff',
  // };

  return (
    <div className="gx-pt-2">
      {paymentStatus === 'PENDING' && transactionStatus === 'FAILED' && (
        <div>
          <Button type="primary" danger onClick={handleRetry}>
            Retry
          </Button>
        </div>
      )}

      {paymentStatus === 'PENDING' && transactionStatus === 'PENDING' && (
        <div>
          <Button type="primary" ghost>
            View
          </Button>
        </div>
      )}

      {paymentStatus === 'PAID' || paymentStatus === 'EXPIRED' ? (
        <div>
          <Button type="primary" ghost>
            View
          </Button>
        </div>
      ) : null}

      {paymentStatus !== 'PENDING' && paymentStatus !== 'PAID' && paymentStatus !== 'EXPIRED' && (
        <Button type="primary" ghost>
          View
        </Button>
      )}
    </div>
  );
};

RetryTransaction.propTypes = {
  paymentStatus: PropTypes.string.isRequired,
  transactionStatus: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
};

export default RetryTransaction;
