/* eslint-disable import/prefer-default-export */
import React from 'react';
import moment from 'moment';
import { CopyOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import RetryTransaction from './RetryTransaction';

export const transactionColumns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: (date) => (
      <>
        <div>
          {moment(date, 'ddd, MMM D, YYYY, HH:mm:ss Z').format('ddd, D MMM YY')}
        </div>
        <div className="gx-text-grey gx-mt-1" style={{ fontSize: '12px' }}>
          {moment(date, 'ddd, MMM D, YYYY, HH:mm:ss Z').format('h:mm:ss a Z')}
        </div>
      </>
    ),
    sorter: true,
    search: false,
  },
  {
    title: 'Reference',
    dataIndex: 'internal_reference',
    render: (reference) => {
      function copyToClipboard(str) {
        const copytext = document.getElementById(str);
        copytext.select();
        copytext.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copytext.value);
      }

      return (
        <div>
          {reference}
          <Input type="text" value={reference} id={reference} style={{ display: 'none' }} />
          <Button
            className="gx-p-0 gx-mt-3 gx-mx-2"
            style={{ lineHeight: 1, border: 0 }}
            title="copy"
            onClick={
            (ev) => { ev.stopPropagation(); copyToClipboard(reference); }

}
          >
            <CopyOutlined />
          </Button>
        </div>
      );
    },
    search: true,
    // sorter: true,
  },
  // {
  //   title: 'Type',
  //   dataIndex: 'transaction_type',
  //   sorter: true,
  // },
  {
    title: 'Requested Amount',
    dataIndex: 'request_amount_str',
  },
  {
    title: 'Transaction Fee',
    dataIndex: 'transaction_fee_str',
  },
  {
    title: 'Status',
    dataIndex: 'transaction_status',
    sorter: true,
    filter: true,
    render: (transactionStatus) => {
      let color = 'default';
      if (
        transactionStatus === 'COMPLETED'
        || transactionStatus === 'COMPLETE'
      ) {
        color = 'success';
      } else if (transactionStatus === 'FAILED') {
        color = 'error';
      } else if (transactionStatus === 'CONFIRMING') {
        color = 'processing';
      } else if (transactionStatus === 'PENDING') {
        color = 'processing';
      } else if (
        transactionStatus === 'CANCELLED'
        || transactionStatus === 'CANCELLED'
      ) {
        color = 'warning';
      }

      return (
        <span className="ant-badge ant-badge-status ant-badge-not-a-wrapper">
          <span className={`ant-badge-status-dot ant-badge-status-${color}`} />
          <span className="ant-badge-status-text">{`${transactionStatus.charAt(0).toUpperCase()}${transactionStatus.slice(1).toLowerCase()}`}</span>
        </span>
      );
    },
  },
  // {
  //   title: 'Message',
  //   dataIndex: 'message',
  // },
  {
    title: 'Method',
    dataIndex: 'provider_code',
    render: (method) => {
      function formatText(text) {
        const spacedText = text.replace(/_/g, ' ');
        const words = spacedText.split(' ');
        const capitalizedWords = words.map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        );

        const formattedText = capitalizedWords.join(' ');
        return formattedText;
      }
      return (
        <div>
          {formatText(method)}
        </div>
      );
    },
    sorter: true,
  },
  {
    title: 'Action',
    dataIndex: '',
    render: (transaction) => (
      <RetryTransaction
        paymentStatus={transaction.invoice_status}
        transactionStatus={transaction.transaction_status}
        invoiceId={transaction.invoice_number}
      />
    ),
  },

];

export const searchableFields = [
  'request_currency',
  'wallet',
  'provider',
  'institution_name',
  'internal_reference',
  'merchant_reference',
  'transaction_status',
];
