import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckCircleFilled, LoadingOutlined, CloseCircleFilled } from '@ant-design/icons';
import {
  Avatar, Col, Row, Button, Modal,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import DepositFormCard from '../../components/common/FormCard';
import { confirmCollection } from '../../redux/actions/Collection';
import { getTransactionStatus } from '../../redux/actions/Data';
import encryptStorage from '../../util/encryption';
import {
  FETCH_SUCCESS,
  RESET_TRANSACTION_COLLECTION_STATE,
  RESET_TRANSACTION_DATA_STATE,
} from '../../constants/ActionTypes';
import CancelButton from '../../components/Widget/CancelButton';
import { getBrowserDetails } from '../../util/helpers';

const ConfirmTransaction = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { transactionData, response, message } = useSelector(({ collection }) => collection);
  const { error } = useSelector(({ common }) => common);

  const {
    transactionStatusDetails,
    cipher,
    paymentOption,
    saveBilling,
    savePhonenumber,
    saveBVN,
  } = useSelector(({ data }) => data);
  const token = encryptStorage.getItem('token');
  const [showProcessing, setShowProcessing] = useState(false);
  const [processingStatus, setProcessingStatus] = useState('Processing');
  const [icon, setIcon] = useState(<LoadingOutlined style={{ color: 'green' }} />);
  const [redirect, setRedirect] = useState(false);
  const [instructions, setInstructions] = useState('');
  const [footerStatus, setFooterStatus] = useState(false);
  const browserDetails = getBrowserDetails();

  const [transaction] = useState({
    id: transactionData.request_id,
    currency: transactionData.request_currency,
    request_amount: transactionData.request_amount,
    transaction_amount: transactionData.transaction_amount,
    provider: transactionData.provider_name,
    charge: transactionData.transaction_charge,
    conversion: transactionData.rate,
    duration: 'Instant',
  });

  const currencyStyle = {
    fontSize: '20px',
    fontWeight: '300',
    margin: '10px 0',
  };

  const { confirm } = Modal;

  const handleConfirmation = () => {
    setShowProcessing(true);
    const params = {
      reference: transactionData.internal_reference,
      token,
      extra_params: browserDetails
    };

    if (paymentOption === 'CARD') {
      params.cipher = cipher;
      params.save_transaction_utils = saveBilling;
    }

    if (paymentOption === 'MOMO') {
      params.save_transaction_utils = savePhonenumber;
    }

    if (paymentOption === 'BANK') {
      params.save_transaction_utils = saveBVN;
    }
    dispatch(confirmCollection(params));
  };

  const showConfirmation = () => {
    confirm({
      title: 'Do you confirm that all transaction details are correct?',
      icon: <CheckCircleFilled className="gx-text-primary" />,
      centered: true,
      onOk() {
        handleConfirmation();
        setRedirect(true);
      },
      okText: 'Yes',
      onCancel() {
      },
      cancelText: 'Let me check',
    });
  };

  const handleRetry = () => {
    dispatch({ type: RESET_TRANSACTION_COLLECTION_STATE });
    dispatch({ type: RESET_TRANSACTION_DATA_STATE });
    dispatch({ type: FETCH_SUCCESS });
  };

  useEffect(() => {
    if (paymentOption === 'BANK') {
      if (response && response.sub_account_details) {
        setInstructions(
          <div>
            Please make a payment of
            {' '}
            <strong>NGN</strong>
            {' '}
            <strong>
              {new Intl.NumberFormat().format(response.sub_account_details.amount)}
            </strong>
            {' '}
            to the following account.
            <br />
            <strong>Account number:</strong>
            {' '}
            {response.sub_account_details.account_no}
            <br />
            <strong>Bank Name:</strong>
            {' '}
            {response.sub_account_details.bank_name}
            <br />
            <strong>Beneficiary:</strong>
            {' '}
            {response.sub_account_details.account_name}
            <br />
            <br />
            <i style={{ color: 'green' }}>
              Reminder: Use this account only for this transaction,
              and make sure to complete the payment before the time specified above expires.
            </i>
          </div>,
        );
      } else if (response && response.payment_url) {
        setInstructions(
          <div>
            You will now be redirected to
            {/* {' '}
            {response.payment_url}
            {' '} */}
            to complete the transaction
          </div>,
        );
      } else {
        setInstructions(message);
      }
    }

    if (paymentOption === 'MOMO') {
      setInstructions(message);
    }

    if (paymentOption === 'CARD') {
      if (response && response.payment_url) {
        setInstructions(
          <div>
            You will now be redirected {' '}
            {/* {' '}
            <span className="gx-text-primary">{response.payment_url}</span>
            {' '} */}
            to complete the transaction.
          </div>,
        );
      } else {
        setInstructions(message);
      }
    }

    if (paymentOption === 'CRYPTO') {
      if (response && response.payment_url) {
        setInstructions(
          <div>
            You will now be redirected {' '}
            {/* {' '}
            <span className="gx-text-primary">{response.payment_url}</span>
            {' '} */}
            to complete the transaction.
          </div>,
        );
      } else {
        setInstructions(message);
      }
    }
  }, [response, message, paymentOption]);

  // Return to enter payment option details page
  useEffect(() => {
    let href = '';
    switch (paymentOption) {
      case 'CARD':
        href = '/card';
        break;
      case 'MOMO':
        href = '/momo';
        break;
      case 'CRYPTO':
        href = '/crypto';
        break;
      default:
        href = '/options';
        break;
    }
    if (!transactionData.request_id) {
      history.push(href);
    }
  }, [history, transactionData, paymentOption]);

  // Get Transaction Status
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showProcessing) {
      const interval = setInterval(() => {
        dispatch(getTransactionStatus(transactionData.internal_reference, token));
      }, 45000);
      return () => clearInterval(interval);
    }
  }, [token, transactionData.internal_reference, dispatch, showProcessing]);

  // Poll For Transaction Status
  useEffect(() => {
    if (error !== '') {
      setIcon(<CloseCircleFilled style={{ color: 'red' }} />);
      setProcessingStatus('Something went wrong');
      setInstructions(error);
      setFooterStatus(true);
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      if (redirect) {
        if (error === '' && response && response.payment_url) {
          setTimeout(() => {
            window.location.href = response.payment_url;
            setRedirect(false);
          }, 3000);
        }
      }
    }

    // if (redirect) {
    //   if (error === '' && response && response.payment_url) {
    //     setTimeout(() => {
    //       window.location.href = response.payment_url;
    //       setRedirect(false);
    //     }, 3000);
    //   }
    // }

    if (transactionStatusDetails.transaction) {
      if (transactionStatusDetails.transaction.status === 'FAILED') {
        setIcon(<CloseCircleFilled style={{ color: 'red' }} />);
        setProcessingStatus('Failed');
        setInstructions(transactionStatusDetails.transaction.status_message);
        setFooterStatus(true);
      }

      if (transactionStatusDetails.transaction.status === 'COMPLETED') {
        setIcon(<CheckCircleFilled style={{ color: 'green' }} />);
        setProcessingStatus('Completed');
        setInstructions(
          <>
            {transactionStatusDetails.transaction.status_message}
            <div className="gx-mt-3">
              Redirecting
              {' '}
              <LoadingOutlined />
            </div>

          </>,
        );
        setTimeout(() => {
          dispatch({ type: RESET_TRANSACTION_COLLECTION_STATE });
          dispatch({ type: RESET_TRANSACTION_DATA_STATE });
          history.push('/transactions');
        }, 5000);
      }
    }
  }, [transactionStatusDetails, redirect, response, history,
    paymentOption, message, dispatch, error]);

  return (
    <>
      <DepositFormCard>
        <div className="gx-text-center">
          <Avatar
            icon={<CheckCircleFilled className="gx-text-black" />}
            size={34}
          />
          <br />
          <br />
          <div className="h4 gx-mb-2">Pay via</div>
          <div className="gx-text-dark h5">{transaction.provider}</div>
          <div style={currencyStyle} className="gx-mb-3">
            {`${transaction.currency} ${new Intl.NumberFormat().format(transaction.request_amount)}`}
          </div>
        </div>

        <Row gutter={24} className="gx-border-bottom gx-py-2">
          <Col span={12}>
            <span className="gx-text-dark">Duration</span>
          </Col>
          <Col span={12} className="gx-text-right">
            <span className="gx-text-black">{transaction.duration}</span>
          </Col>
        </Row>

        <Row gutter={24} className="gx-border-bottom gx-py-3">
          <Col span={12}>
            <span className="gx-text-dark">Wallet Top up Fee</span>
          </Col>
          <Col span={12} className="gx-text-right">
            <span className="gx-text-black">
              {`${transaction.currency} ${new Intl.NumberFormat().format(transaction.charge)}`}
            </span>
          </Col>
        </Row>

        <Row gutter={24} className="gx-py-3">
          <Col span={12}>
            <span className="gx-text-dark">Transaction total</span>
          </Col>
          <Col span={12} className="gx-text-right">
            <span className="gx-text-black">
              {`${transaction.currency} ${new Intl.NumberFormat().format(transaction.transaction_amount)}`}
            </span>
          </Col>
        </Row>
      </DepositFormCard>

      <div className="gx-flex-row">
        <Button
          type="primary"
          className="gx-mb-0"
          onClick={handleRetry}
          ghost
        >
          Back
        </Button>
        <div className="gx-ml-auto" />
        <CancelButton />
        <Button
          type="primary"
          onClick={showConfirmation}
          className="gx-mb-0"
          htmlType="button"
        >
          Confirm
        </Button>
      </div>

      <Modal
        title={null}
        visible={showProcessing}
        closable={false}
        centered
        footer={
          footerStatus
            ? <div><Button onClick={() => { history.push('/transactions') }} type="danger" ghost>Cancel</Button> <Button onClick={handleRetry} type="primary" ghost>Try Again</Button></div> : null
        }
      >
        <div>
          {icon}
          {' '}
          {processingStatus}
        </div>

        <div style={{ padding: '10px 15px' }}>{instructions}</div>
      </Modal>

    </>
  );
};

export default ConfirmTransaction;
