import {
  FETCH_COUNTRIES_INIT,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_ERROR,
  // INDEX_PAGE_SIZE_DEFAULT,
  FETCH_WALLETS_INIT,
  GET_INVOICE_DETAILS,
  GET_TRANSACTION_STATUS,
  GET_PAYMENT_PROVIDERS,
  FETCH_WALLETS_SUCCESS,
  FETCH_WALLETS_ERROR,
  SET_SELECTED_WALLET,
  CREATE_USER_WALLET_INIT,
  CREATE_USER_WALLET_SUCCESS,
  CREATE_USER_WALLET_ERROR,
  SET_INVOICE_ID,
  RESET_TRANSACTION_DATA_STATE,
  SET_CIPHER,
  UNSET_CIPHER,
  SET_PAYMENT_OPTION,
  RESET_PAYMENT_OPTION,
  SAVE_BILLING_DETAILS,
  SAVE_PHONE_NUMBER,
  SAVE_BVN,
  RESET_INVOICE_DETAILS,
  GET_SUPPORTED_CURRENCIES,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  countries: {
    data: [],
    loading: false,
    error: null,
    creatingCountry: false,
    deletingCountry: false,
    updatingCountry: false,
  },
  supportedCurrencies: [],
  currencies: {
    data: [],
    loading: false,
    error: null,
  },
  invoiceDetails: {},
  invoiceId: null,
  transactionStatusCode: null,
  transactionStatusDetails: {},
  getProviderStatusCode: null,
  getProviderDetails: {},
  wallets: {
    data: [],
    loading: false,
    error: null,
    creatingWallet: false,
  },
  activeWallet: {},
  cipher: null,
  saveBilling: true,
  savePhonenumber: true,
  saveBVN: true,
  paymentOption: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUPPORTED_CURRENCIES: {
      return {
        ...state,
        currencies: {
          data: action.payload,
        },
      };
    }

    case GET_INVOICE_DETAILS: {
      return {
        ...state,
        invoiceDetails: action.payload,
      };
    }

    case RESET_INVOICE_DETAILS: {
      return {
        ...state,
        invoiceId: null,
        invoiceDetails: {},
      };
    }

    case SET_INVOICE_ID: {
      return {
        ...state,
        invoiceId: action.payload,
      };
    }

    case GET_TRANSACTION_STATUS: {
      return {
        ...state,
        transactionStatusCode: action.payload.code,
        transactionStatusDetails: action.payload.details,
      };
    }

    case GET_PAYMENT_PROVIDERS: {
      return {
        ...state,
        getProviderStatusCode: action.payload.code,
        getProviderDetails: action.payload.data,
      };
    }

    case RESET_TRANSACTION_DATA_STATE: {
      return {
        ...state,
        transactionStatusCode: null,
        transactionStatusDetails: {},
      };
    }

    case FETCH_COUNTRIES_INIT: {
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: true,
        },
      };
    }

    case FETCH_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: false,
          data: action.payload,
        },
      };
    }

    case FETCH_COUNTRIES_ERROR: {
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: false,
          error: true,
        },
      };
    }

    case FETCH_WALLETS_INIT: {
      return {
        ...state,
        wallets: {
          ...state.wallets,
          loading: true,
        },
      };
    }

    case FETCH_WALLETS_SUCCESS: {
      return {
        ...state,
        wallets: {
          ...state.wallets,
          loading: false,
          data: action.payload,
        },
      };
    }

    case FETCH_WALLETS_ERROR: {
      return {
        ...state,
        wallets: {
          ...state.wallets,
          loading: false,
          error: action.payload,
        },
      };
    }

    case CREATE_USER_WALLET_INIT: {
      return {
        ...state,
        wallets: {
          ...state.wallets,
          creatingWallet: true,
        },
      };
    }

    case CREATE_USER_WALLET_SUCCESS: {
      // const allWallets = state.wallets.data;
      // allWallets.push(action.payload);

      // return {
      //   ...state,
      //   wallets: {
      //     ...state.wallets,
      //     creatingWallet: false,
      //     data: allWallets,
      //   },
      // };

      return {
        ...state,
        wallets: {
          ...state.wallets,
          creatingWallet: false,
        },
      };
    }

    case CREATE_USER_WALLET_ERROR: {
      return {
        ...state,
        wallets: {
          ...state.wallets,
          error: false,
        },
      };
    }

    case SET_SELECTED_WALLET: {
      return {
        ...state,
        activeWallet: action.payload,
      };
    }

    case SET_CIPHER: {
      return {
        ...state,
        cipher: action.payload,
      };
    }

    case SAVE_BILLING_DETAILS: {
      return {
        ...state,
        saveBilling: action.payload,
      };
    }

    case SAVE_PHONE_NUMBER: {
      return {
        ...state,
        savePhonenumber: action.payload,
      };
    }

    case SAVE_BVN: {
      return {
        ...state,
        saveBVN: action.payload,
      };
    }

    case UNSET_CIPHER: {
      return {
        ...state,
        cipher: null,
      };
    }

    case SET_PAYMENT_OPTION: {
      return {
        ...state,
        paymentOption: action.payload,
      };
    }

    case RESET_PAYMENT_OPTION: {
      return {
        ...state,
        paymentOption: null,
      };
    }

    default:
      return state;
  }
};
