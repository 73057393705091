import React, {
  // useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import {
  Input, Select, Form, Button, Tag,
} from 'antd';
// import {
//   //useDispatch,
//   useSelector,
// } from 'react-redux';
// import encryptStorage from '../../util/encryption';
import DepositFormCard from '../../components/common/FormCard';
// import { initializeCollection } from '../../redux/actions/Collection';

const { Option } = Select;

const TransactionAmountInput = (props) => {
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('UGX');
  // const dispatch = useDispatch();
  const history = useHistory();
  const { href, inputPosition } = props;
  // const user = useSelector(({auth} ) => auth.authUser);
  // const { initializeCollectionCode } = useSelector(({ collection }) => collection);
  // const phoneNumber = encryptStorage.getItem('phoneNumber');
  // const phoneNumberTwo = user.phone;
  // const description = `QWAAP Wallet depos
  // it for ${user.full_name}`|| "Intial QWAAP wallet deposit"

  // const phone = phoneNumber || '+256777004449';

  const inputStyle = {
    fontSize: '50px',
    fontWeight: '300',
    height: '50px',
    width: '100',
    textAlign: `${inputPosition}`,
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/\D/, '').substring(0.6);
    setAmount(value);
  };

  const onFinish = () => {
    // const collection = {
    //   customer_reference: 'auto',
    //   transaction_origin: 'APP',
    //   transaction_method: 'MOBILE_MONEY',
    //   currency: 'UGX',
    //   amount: Number(amount),
    //   provider_code: 'unknown',
    //   description: 'QWAAP Wallet deposit',
    //   msisdn: phone.substring(1),
    // };
    // console.log(collection);
    // console.log(type);
    // dispatch(initializeCollection(collection));
    history.push(href);
  };

  // useEffect(() => {
  //   if (initializeCollectionCode === 200) {
  //     history.push(href);
  //   }
  // });

  const handleCurrencyChange = (curr) => {
    setCurrency(curr);
  };

  return (
    <>
      <Form onFinish={onFinish} className="gx-signin-form">
        <DepositFormCard>
          <Form.Item className="gx-text-cente gx-mx-0">
            <Select
              defaultValue={currency}
              size="large"
              style={{ width: '100px' }}
              onChange={handleCurrencyChange}
            >
              <Option value="UGX">UGX</Option>
              {/* <Option value="EUR">EUR</Option>
              <Option value="GBP">GBP</Option>
              <Option value="CNY">CNY</Option> */}
            </Select>
          </Form.Item>
          <Form.Item className="gx-mx-0">
            <Input
              type="text"
              bordered={false}
              size="large"
              style={inputStyle}
              placeholder="0"
              value={amount}
              onChange={handleAmountChange}
            />
          </Form.Item>

          <Form.Item className="gx-text-cente gx-mx-0">
            <Tag>
              Min:
              {currency}
              {' '}
              5,000
            </Tag>
            {' '}
            <Tag>
              Max:
              {currency}
              {' '}
              500,000
            </Tag>
          </Form.Item>
        </DepositFormCard>

        <div className="gx-flex-row gx-mx-0">
          <Button
            type="primary-5"
            className="gx-mb-0"
            onClick={() => history.goBack()}
          >
            Back
          </Button>

          <Button
            type="primary"
            style={{ marginLeft: 'auto' }}
            className={`gx-mb-0 ${amount ? 'enabled' : ' disabled'}`}
            htmlType={`${amount ? 'submit' : 'button'}`}
          >
            Continue
          </Button>
        </div>
      </Form>
    </>
  );
};

TransactionAmountInput.defaultProps = {
  inputPosition: '',
};

TransactionAmountInput.propTypes = {
  href: PropTypes.string.isRequired,
  inputPosition: PropTypes.string,
};

export default TransactionAmountInput;
