import React, { useEffect, useState } from 'react';
import {
  Button, Skeleton,
} from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceDetails } from '../../redux/actions/Data';
import { RESET_INVOICE_DETAILS, SET_INVOICE_ID,RESET_EXPRESS_LOGIN_CODE } from '../../constants/ActionTypes';
import AuthCardFormContainer from '../../containers/common/AuthCardFormContainer';
import { expressLogOut } from '../../redux/actions/Auth';

const Pay = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { invoiceDetails } = useSelector(({ data }) => data);
  const { loading } = useSelector(({ common }) => common);
  const { authUser,token } = useSelector(({ auth }) => auth);
  const [showCard, setShowCard] = useState(false);

  const handleReturn = () => {
    dispatch({ type: RESET_INVOICE_DETAILS });
    window.location.href = invoiceDetails.redirect_url;
  };

  const handleReturnSignIn = () => {
    dispatch({ type: RESET_INVOICE_DETAILS });
    history.push('/signin');
  };

  useEffect(() => {
    if (token == null){
    dispatch({ type: RESET_EXPRESS_LOGIN_CODE});
    }

  }, [token, dispatch]);

  useEffect(() => {
    if (Object.keys(authUser).length > 0) {
      if (authUser.email !== invoiceDetails.customer_email) {
        dispatch(expressLogOut());
      }
    }
  }, [dispatch, authUser, invoiceDetails]);

  useEffect(() => {
    dispatch(getInvoiceDetails(id));
    dispatch({ type: SET_INVOICE_ID, payload: id });
    if (invoiceDetails.payment_status === 'PENDING') {
      history.push(`/signin?invoice_id=${id}`);
    }
    setShowCard(true);
  }, [dispatch, history, id, invoiceDetails.payment_status]);

  return (
    <>
      {showCard
        && (

        <AuthCardFormContainer>
          <Skeleton
            loading={loading}
            active={loading}
            paragraph={{ rows: 2 }}
          >
            <div className="gx-text-center gx-pt-4">
              <h4 className="gx-mb-3">
                {invoiceDetails.payment_status}
                {' '}
                INVOICE
              </h4>
              <p className="gx-font-weight-bold">
                {' '}
                Your transaction of
                {' '}
                {invoiceDetails.currency}
                {' '}
                {new Intl.NumberFormat().format(invoiceDetails.amount)}
                {' '}
                is
                {' '}
                {invoiceDetails.payment_status}
                .
              </p>
              <p>
                Please return to the merchant&apos;s website to initiate a new
                transaction or sign in to view your transactions.
              </p>
              <br />
              <Button type="primary" onClick={handleReturn}>
                Return to merchant
              </Button>

              <Button type="primary" ghost onClick={handleReturnSignIn}>
                Account
              </Button>
            </div>
          </Skeleton>
        </AuthCardFormContainer>
        )}
    </>
  );
};

export default Pay;
