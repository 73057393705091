import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  Input,
} from 'antd';
import InfoView from 'components/common/InfoView';
import { requestExpressLoginOtp } from '../../redux/actions/Auth';
import AuthCardFormContainer from '../common/AuthCardFormContainer';
import { showErrorMessage } from '../../redux/actions/Common';

const SignIn = () => {
  const dispatch = useDispatch();
  const { requestExpressLoginOtpCode } = useSelector(({ auth }) => auth);
  const { invoiceDetails } = useSelector(({ data }) => data);
  const { loading } = useSelector(({ common }) => common);
  const [email, setEmail] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (invoiceDetails.customer_email) {
      setEmail(invoiceDetails.customer_email);
    }
  }, [loading, invoiceDetails]);

  // Call when form submission fails
  const onFinishFailed = () => {
    dispatch(showErrorMessage('Sign in failed, please try again'));
  };

  // Call on form submit
  const onFinish = () => {
    dispatch(requestExpressLoginOtp(email));
  };

  useEffect(() => {
    if (requestExpressLoginOtpCode === 200) {
      history.push('/otp');
    }
  }, [requestExpressLoginOtpCode, history]);

  useEffect(() => {
    document.title = 'Sign In';
  }, []);

  return (
    <>
      <AuthCardFormContainer>
        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <div className="h4 gx-mb-3 gx-mt-4 gx-text-center">Email</div>

          <Form.Item>
            <Input
              size="large"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item className="gx-mb-0 gx-text-center">
            <Button
              block
              type="primary"
              size="large"
              className={`gx-mb-0 ${email && !loading ? 'enabled' : 'disabled'}`}
              htmlType={email ? 'submit' : 'button'}
              loading={loading}
            >
              {!loading && ('Continue')}
            </Button>
          </Form.Item>
        </Form>
      </AuthCardFormContainer>
      <InfoView showLoader={false} />
    </>
  );
};

export default SignIn;
