/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'antd';
import Topbar from '../../containers/Topbar/app';
import ProgressTitle from './ProgressTitle';
import RegistrationProgress from '../../components/common/RegistrationProgress/RegistrationProgress';
import AddPaymentCard from '../../containers/common/AddPaymentCard';
import { SET_PAYMENT_OPTION } from '../../constants/ActionTypes';
import PayCard from '../../containers/common/PayCard';

const CardP = () => {
  const { invoiceDetails } = useSelector(({ data }) => data);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Pay with card';
  }, []);

  useEffect(() => {
    dispatch({ type: SET_PAYMENT_OPTION, payload: 'CARD' });
  }, [dispatch]);

  return (
    <div className="gx-app-layout-">
      <Topbar />
      <div
        className="complete-registration-content-wrapper"
        style={{ margin: '2% auto' }}
      >
        <ProgressTitle title="Pay with card" />

        <RegistrationProgress
          first="complete"
          second="active"
          third=""
          fourth=""
        />
        <Row gutter={24} className="gx-mx-0">
          <Col xl={8} lg={12} md={16} sm={24} xs={24} className="gx-mx-auto gx-my-5">
            <PayCard
              amount={(invoiceDetails.amount).toString()}
              currency={(invoiceDetails.currency).toString()}
            />
            <br />
            <AddPaymentCard href="/confirm" />
          </Col>

        </Row>
      </div>
    </div>
  );
};

CardP.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
  }).isRequired,
};

export default CardP;
