import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  RETURN_TO_EXPRESS_LOGIN,
  EXPRESS_LOGIN_FAILURE,
  PHONENUMBER_SUCCESS,
  EXPRESS_LOGIN_SUCCESS,
  REQUEST_EXPRESS_LOGIN_OTP_SUCCESS,
  REQUEST_OTP_SUCCESS,
  VERIFY_OTP_SUCCESS,
  ACTIVATE_USER_SUCCESS,
  LOGIN_VERIFY_OTP_SUCCESS,
  VERIFY_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  PASSWORD_REQUEST_SUCCESS,
  REQUIRE_OTP,
  REQUIRE_OTP_CANCELLED,
  RESET_EXPRESS_LOGIN_CODE,
  UPDATE_USER_BILLING_ADDRESS,
  UPDATE_USER_PHONE_NUMBER,
  UPDATE_USER_BVN,
} from 'constants/ActionTypes';

const INIT_STATE = {
  token: null,
  requireOtp: false,
  phoneVerificationStatusCode: null,
  expressLoginCode: null,
  requestExpressLoginOtpCode: null,
  requestOtpStatusCode: null,
  kycStatusCode: null,
  verifyOtpStatusCode: null,
  initURL: '',
  authUser: {},
  activationCode: null,
  selectedUser: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        state: null,
      };
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case UPDATE_USER_BILLING_ADDRESS: {
      return {
        ...state,
        authUser: {
          ...state.authUser,
          billing_details: action.payload,
        },
      };
    }

    case UPDATE_USER_PHONE_NUMBER: {
      const updatedAccounts = state.authUser.accounts.map((account) => {
        if (account.currency === action.payload.currency) {
          return {
            ...account,
            account_numbers: [...account.account_numbers, action.payload.phoneNumber],
          };
        }
        return account;
      });

      if (!updatedAccounts.some((account) => account.currency === action.payload.currency)) {
        updatedAccounts.push({
          currency: action.payload.currency,
          account_numbers: [action.payload.phoneNumber],
        });
      }

      return {
        ...state,
        authUser: {
          ...state.authUser,
          accounts: updatedAccounts,
        },
      };
    }

    case UPDATE_USER_BVN: {
      return {
        ...state,
        authUser: {
          ...state.authUser,
          bvn: action.payload,
        },
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case REQUEST_EXPRESS_LOGIN_OTP_SUCCESS: {
      return {
        ...state,
        requestExpressLoginOtpCode: action.payload,
      };
    }

    case EXPRESS_LOGIN_SUCCESS: {
      return {
        ...state,
        expressLoginCode: action.payload,
      };
    }

    case RESET_EXPRESS_LOGIN_CODE: {
      return {
        ...state,
        requestExpressLoginOtpCode: null,
      }
    }

    case RETURN_TO_EXPRESS_LOGIN: {
      return {
        ...state,
        expressLoginCode: null,
        requestExpressLoginOtpCode: null,
        loading: false,
      };
    }

    case EXPRESS_LOGIN_FAILURE: {
      return {
        ...state,
        loginFailMessage: action.payload,
      };
    }

    case REQUEST_OTP_SUCCESS: {
      return {
        ...state,
        requestOtpStatusCode: action.payload.code,
        tfa: {
          identifier: action.payload.identifier,
        },
      };
    }

    case VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        verifyOtpStatusCode: action.payload,
      };
    }

    case LOGIN_VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        loginVerifyOtpStatusCode: action.payload.code,
        authUser: action.payload.user,
      };
    }

    case PASSWORD_REQUEST_SUCCESS: {
      return {
        ...state,
        passwordResetStatusCode: action.payload,
      };
    }

    case ACTIVATE_USER_SUCCESS: {
      return {
        ...state,
        activationCode: action.payload,
      };
    }

    case VERIFY_USER_SUCCESS: {
      return {
        ...state,
        verificationStatusCode: action.payload,
      };
    }

    case PHONENUMBER_SUCCESS: {
      return {
        ...state,
        phoneVerificationStatusCode: action.payload,
      };
    }

    case REQUIRE_OTP: {
      return {
        ...state,
        requireOtp: true,
      };
    }

    case REQUIRE_OTP_CANCELLED: {
      return {
        ...state,
        requireOtp: false,
      };
    }

    case UPDATE_USER_SUCCESS: {
      const updatedData = state.users.data;
      const index = updatedData.findIndex(
        (key) => key._id === action.payload._id,
      );
      updatedData[index] = action.payload;
      return {
        ...state,
        users: {
          ...state.users,
          updatingUser: false,
          data: updatedData,
        },
      };
    }

    default:
      return state;
  }
};
