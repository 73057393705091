const crypto = require('crypto');

export default function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'UTC',
    hour12: false,
  };

  const formattedDate = date.toLocaleString('en-US', options);
  return `${formattedDate} EAT`;
}

export function getBrowserDetails() {
  const { colorDepth, height, width } = window.screen;
  const { userAgent } = navigator;

  const details = {
    accept_header: document.contentType,
    screen_color_depth: String(colorDepth),
    language: navigator.language || navigator.userLanguage,
    screen_height: String(height),
    screen_width: String(width),
    timezone: String(new Date().getTimezoneOffset()),
    java_enabled: "false",
    javascript_enabled: "true",
    user_agent: userAgent,
  };

  return details;
}

const key = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEArmY50RSv9fo0oZpi9V3P
X+Dt5plvp8d160xaV3RP246eVqLPzLdmklAy2h2tmx13A18DvL4nkVqcNZBPfTS7
kz3uvOLTMMMwb9+8/xGV/vOWgBJVfzvfN5Fm2JXp3ru9XEIszp17/8KoBKNNwE1X
gD8hXamq5zWK7U2yDFREL9tgvnH92g/ZMmVjGZi51M2h7WU6B0eHx57NjbDoWjKc
pK+BHajmmotzlgDAeEc/843mI4hpx4OFjKLRZIUv2eScCQ5E82Gy3Fslkv7A3aVv
jXw5/C6UhATLSgNbcvvUpP0fZ9iDMiRc1bij1mdBR76WKTd8+1WZQ+WGKf2IEC7n
Ba3KbjjrIgulrBjV3FnFuPs6kUuYUwQAnzoucWlaFJPJDwtQHDP+5uFlK4V1s/9W
mhzbHiKRWHP32MDaYZ+AeB8UrKna9Ojhtn2V+3zVvUJ2qv8/XA/2PAm+TmZNJKTR
JxRf8v/vFydeLgGud+IWomrnGsVRD9/rlwb5qETJ8Vy8ttipLhx+3Vcj+iV3wq2c
BFvrHPdlKzk9M0TCTeFwR1je+vSiO1wxwRZPvzRF9wIggvfBwId5iTojTI2+mTJD
fD9YPtIIxaL15Wv079hElsswt3IBmAxZR2HBR0NEVlZmMlvvRWNhUdDg4F8SKmJW
d0brUk7jkqRoUOz60z0IZAsCAwEAAQ
-----END PUBLIC KEY-----`;

const productionKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAxHtpmlBLq65roQAabI+1
6weNg092BEI/urljp3Kk9iWhXH+zD5tcysg74+n3NnreK6VmFiJGFZ/SzVg/J1ib
bwBuoXPpJoR7xOktCc8cs/Bv/OjPJ/DWf8kc3ZfeAT9jkHgs2rGHI0IdfkhUrz3Y
/FE1UtGZw10VTegI1CygCHneaWvUw84uafjglMoSZEySQ5ryne4qVD506uu5oWeV
qjmw1zeRiuglqbFsorurpHjyiUUQQEg/l3JmHyRhwGYg2thsOTgjN61wTZYYmwKn
RfyzdAsvblhUatbYxUAkvKoaPnloeFabbSmxg5K1qD2KQVSunD+sAMAN7NOlhFRN
CvA+bQGv6z3f0m0tII01eChx9Fgwh6+ob/18VEprjlUDK6fGDlNO6iipcwEbJ3aK
6hSPDlTujL5O9uk0mVpqPYWLBV3QpmDmp4HQWEVazN7MsVQl8XMqYKUDWAAboIOv
ieyYWZMvXDmq2dhC6SAwOrG4RItuJgVDTKj3j6luxZtJfXZr61ylQsv8ir8WLvEp
qB0/Q25ZsmNV7fgkP1q2YHwNErjXyxmQKtyUe/3xuyGXxote4Tt1U6JAh6awwrVP
zA6sO7N019XfrphQjvNoNu/qR1vEIilDAsmsEF9dhnsaPR+fViJbzLOfv6FtYTXP
EffMptPkw8HE1YKMlBQBkOkCAwEAAQ==
-----END PUBLIC KEY-----`;

export function encryptData(data) {
  const payload = JSON.stringify(data);
  let publicKey =" ";

  if(process.env.REACT_APP_ENVIRONMENT == 'production'){
    publicKey = productionKey;
  } else {
    publicKey = key;
  }

  const encryptedData = crypto.publicEncrypt(
    {
      key: publicKey,
      padding: crypto.constants.RSA_PKCS1_PADDING,
    },
    Buffer.from(payload),
  );

  /* return the base64 encoded version of the encrypted string */
  return encryptedData.toString('base64');
}



export function stripTrailingSlash(urlString) {
  return urlString.endsWith('/') ? urlString.slice(0, -1) : urlString;
}
