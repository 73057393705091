import React from 'react';
import {
  Card, Skeleton, Row, Col,
} from 'antd';
import { PropTypes } from 'prop-types';
import Footer from '../../components/common/Footer';
import Logo from '../../components/common/Logos';

function CardItem({ children, ...props }) {
  const { loading } = props;

  const cardStyle = {
    boxShadow: '0 0 60px 0 rgba(208, 216, 243, 0.8)',
    borderRadius: '10px',
  };

  return (
    <div className="gx-flex-column gx-justify-content-center gx-h-100">
      <Row gutter={24} className="gx-mx-0">
        <Col xl={6} lg={8} md={16} sm={24} xs={24} className="gx-mx-auto">
          <div
            className="gx-app-login-contair"
          >
            <Card style={cardStyle}>
              <Skeleton loading={loading} active>
                <Logo />
                {children}
              </Skeleton>
            </Card>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

CardItem.defaultProps = {

  children: [],

  loading: false,
};

CardItem.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default CardItem;
