import React from 'react';
import { Row, Col, Button } from 'antd';
import { goBack } from 'react-router-redux';

const PageNotFound = () => (
  <Row gutter={24} className="h-100">
    <Col xl={8} lg={8} md={12} xs={24} sm={24} className="gx-text-center gx-mx-auto gx-h-100 gx-justify-content-middle">
      <div style={{ padding: '10% 0' }}>
        <h2>404</h2>
        Page Not Found
      </div>
      <br />
      <Button type="primary" onClick={() => goBack()}>Go Back</Button>
      <br />
      <br />
    </Col>
  </Row>
);

export default PageNotFound;
