/* eslint-disable linebreak-style */
/* eslint-disable no-param-reassign */
import axios from 'util/Api';
// import base64 from 'base-64';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  VERIFY_OTP_SUCCESS,
  PHONENUMBER_SUCCESS,
  REQUEST_OTP_SUCCESS,
  USER_TOKEN_SET,
  SHOW_MESSAGE,
  EXPRESS_LOGIN_SUCCESS,
  REQUEST_EXPRESS_LOGIN_OTP_SUCCESS,
  SHOW_ERROR_MESSAGE,
  LOGIN_VERIFY_OTP_SUCCESS,
  ACTIVATE_USER_ERROR,
  PASSWORD_REQUEST_SUCCESS,
} from 'constants/ActionTypes';
import encryptStorage from '../../util/encryption';
import { PASSWORD_RESET } from '../../constants/OtpPurpose';
import { RESET_STATE, USER_DATA } from '../../constants/ActionTypes';

export const setInitUrl = (url) => ({
  type: INIT_URL,
  payload: url,
});

// EXPRESS-LOGIN
export const expressLogin = (email, code) => (dispatch) => {
  dispatch({ type: FETCH_START });
  axios.post('/users/express-login', {
    email,
    verification_code: code,
  }).then(({ data }) => {
    if (data.code === 200) {
      encryptStorage.setItem('token', data.data.tokens.access_token);
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: USER_TOKEN_SET, payload: data.data.tokens.access_token });
      dispatch({ type: SHOW_MESSAGE, payload: 'OTP successfully verified' });
      dispatch({ type: EXPRESS_LOGIN_SUCCESS, payload: data.code });
      dispatch({ type: USER_DATA, payload: data.data.user });
      encryptStorage.setItem(
        'refresh_token',
        data.data.tokens.refresh_token,
      );
      // console.log(data);
    } else {
      dispatch({ type: FETCH_ERROR, payload: data.message });
    }
  })
    .catch((error) => {
      if (error.response && error.response.data.message) {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
      } else {
        const message = 'We were unable to verify your OTP, please try again';
        dispatch({ type: FETCH_ERROR, payload: message });
      }
      // console.log(error);
      console.error('Error: ', error.message);
    });
};

// REQUEST EXPRESS-LOGIN OTP
export const requestExpressLoginOtp = (email) => (dispatch) => {
  dispatch({ type: FETCH_START });

  axios.post('users/request-express-otp', {
    email,
  }).then(({ data }) => {
    // console.log(data);
    if (data.code === 200) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: USER_DATA, payload: { email } });
      dispatch({ type: SHOW_MESSAGE, payload: 'OTP sent' });
      dispatch({ type: REQUEST_EXPRESS_LOGIN_OTP_SUCCESS, payload: data.code });
      // console.log(data);
    } else {
      dispatch({ type: FETCH_ERROR, payload: data.message });
    }
  })
    .catch((error) => {
      if (error.response && error.response.data.message) {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
      } else {
        const message = 'We were unable to send your OTP for Express Login. Please try again';
        dispatch({ type: FETCH_ERROR, payload: message });
      }
      console.error('Error: ', error.message);
    });
};

// VERIFY PHONENUMBER
export const userVerifyPhoneNumber = (number, code) => (dispatch) => {
  dispatch({ type: FETCH_START });
  axios
    .post('/users/verify-phone', {
      customer_phonenumber: number,
      verification_code: code,
    })
    .then(({ data }) => {
      if (data?.code === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
        dispatch({ type: PHONENUMBER_SUCCESS, payload: data.code });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data.message) {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
      } else {
        const message = 'We were unable to verify your phone number, please try again';
        dispatch({ type: ACTIVATE_USER_ERROR, payload: message });
      }
      console.error('Error: ', error.message);
    });
};

// REQUEST OTP
export const requestOtp = (identifier, purpose) => (dispatch) => {
  dispatch({ type: FETCH_START });
  axios
    .post('/users/request-otp', {
      user_identifier: identifier,
      otp_purpose: purpose,
    })
    .then(({ data }) => {
      if (data?.code === 200) {
        dispatch({ type: FETCH_SUCCESS });

        if (purpose === PASSWORD_RESET) {
          dispatch({ type: PASSWORD_REQUEST_SUCCESS, payload: data.code });
        } else {
          dispatch({
            type: REQUEST_OTP_SUCCESS,
            payload: { code: data.code, identifier },
          });
        }
        dispatch({ type: SHOW_MESSAGE, payload: 'Code sent' });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data.message) {
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: error.response.data.message,
        });
      } else {
        const message = 'We were unable to send you an otp at the moment, please try again';
        dispatch({ type: SHOW_ERROR_MESSAGE, payload: message });
      }
    });
};

// VERIFY OTP
export const verifyOTP = (identifier, code, purpose) => (dispatch) => {
  dispatch({ type: FETCH_START });
  axios
    .post('/users/verify-otp', {
      user_identifier: identifier,
      verification_code: code,
      otp_purpose: purpose,
    })
    .then(({ data }) => {
      if (data?.code === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });

        if (purpose === 'LOGIN_AUTH') {
          // console.log(purpose);
          dispatch({ type: LOGIN_VERIFY_OTP_SUCCESS, payload: data.code });
          encryptStorage.setItem('token', data.data.tokens.access_token);
          encryptStorage.setItem(
            'refresh_token',
            data.data.tokens.refresh_token,
          );
        } else {
          dispatch({ type: VERIFY_OTP_SUCCESS, payload: data.code });
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data.message) {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
      } else {
        const message = 'We were unable to verify your OTP, please try again';
        dispatch({ type: ACTIVATE_USER_ERROR, payload: message });
      }
      console.error('Error: ', error.message);
    });
};

// LOGOUT USER
export const userSignOut = (token) => (dispatch) => {
  dispatch({ type: FETCH_START });
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  axios
    .post('/users/logout', { headers })
    .then(({ data }) => {
      if (data.code === 200) {
        encryptStorage.removeItem('token');
        encryptStorage.clear();
        localStorage.clear();
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: RESET_STATE });
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data.message) {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
      } else {
        const message = 'An error occurred while signing you out';
        dispatch({ type: FETCH_ERROR, payload: message });
      }
      console.error('Error: ', error.message);
    });
};

// Express Logout

export const expressLogOut = () => (dispatch) => {
  dispatch({ type: RESET_STATE });
  encryptStorage.clear();
};
