import React from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Popover, Button } from 'antd';
import placeholder from '../../assets/images/placeholder.jpg';
import { userSignOut } from '../../redux/actions/Auth';

const UserInfo = () => {
  const dispatch = useDispatch();

  const userMenuOptions = (
    <div className="gx-user-popover">
      <Button
        onClick={() => {
          dispatch(userSignOut());
        }}
      >
        Logout
      </Button>
    </div>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar src={placeholder} className="gx-avatar gx-pointer" alt="" />
    </Popover>
  );
};

export default UserInfo;
