import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Divider,
  Modal,
  Select,
  Switch,
} from 'antd';
import {
  CreditCardOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons';
import visaImg from '../../assets/images/img__visa.png';
import mCardImg from '../../assets/images/img__mcard.png';
import countries from '../../util/countries_with_states.json';
import FormCard from '../../components/common/FormCard';
import encryptStorage from '../../util/encryption';
import { initializeCollection } from '../../redux/actions/Collection';
import { getPaymentOptions } from '../../redux/actions/Data';
import InfoView from '../../components/common/InfoView';
import { encryptData, stripTrailingSlash } from '../../util/helpers';
import { SET_CIPHER, SAVE_BILLING_DETAILS, UPDATE_USER_BILLING_ADDRESS } from '../../constants/ActionTypes';
import CancelButton from '../../components/Widget/CancelButton';

const AddPaymentCard = (props) => {
  const { href } = props;
  const [cardholdername, setCardholdername] = useState('');
  const [cardnumber, setCardnumber] = useState();
  const [expiryMonth, setExpiryMonth] = useState();
  const [expiryYear, setExpiryYear] = useState();
  const [cardCVV, setCardCVV] = useState('');
  const [address1, setAddress1] = useState('');
  const [country, setCountry] = useState();
  const [city, setCity] = useState('');
  const [region, setRegion] = useState();
  const [zip, setZip] = useState('');
  const [savebilling, setsavebilling] = useState(true);
  const [changeBillingAddress, setChangeBillingAddress] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cardIcon, setCardIcon] = useState(<CreditCardOutlined className="gx-text-muted" />);
  const { invoiceDetails } = useSelector(({ data }) => data);
  const { getProviderDetails } = useSelector(({ data }) => data);
  const billingDetails = useSelector(({ auth }) => auth.authUser.billing_details);
  const [states, setStates] = useState();
  const { currency } = invoiceDetails;
  const dispatch = useDispatch();
  // const allInput = amount !== 0;
  const option = 'CARD';
  const token = encryptStorage.getItem('token');
  const { initializeCollectionCode } = useSelector(({ collection }) => collection);
  const baseUrl = (process.env.REACT_APP_BASE_URL).toString();

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const returnYears = () => {
    const yrs = [];
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i += 1) {
      yrs.push(currentYear + i);
    }
    return yrs;
  };
  const years = returnYears();

  const history = useHistory();

  const onFinish = () => {
    const cipher = encryptData({
      full_name: cardholdername.toString(),
      card_no: cardnumber.replace(/\s/g, ''),
      exp_month: expiryMonth.toString().padStart(0, 2),
      exp_year: expiryYear.toString(),
      cvv: cardCVV,
      billing_address: address1,
      billing_city: city.toString(),
      billing_state: region.toString(),
      billing_zip: zip.toString(),
      billing_country: country.toString(),
    });

    dispatch({ type: SET_CIPHER, payload: cipher });
    dispatch({ type: SAVE_BILLING_DETAILS, payload: savebilling });
    dispatch({
      type: UPDATE_USER_BILLING_ADDRESS,
      payload: {
        billing_address: address1,
        billing_city: city,
        billing_country: country,
        billing_state: region,
        billing_zip: zip,
      },
    });

    // implementation goes here
    const collection = {
      customer_reference: 'auto',
      invoice_id: invoiceDetails.id,
      transaction_origin: 'APP',
      transaction_method: option,
      currency,
      amount: invoiceDetails.amount,
      provider_code: getProviderDetails.provider_code,
      description: `QWAAP Wallet deposit for ${invoiceDetails.id}`,
      account_number: '256777000123',
      redirect_url: `${stripTrailingSlash(baseUrl)}/check-status`,
    };
    dispatch(initializeCollection(collection, token));
  };

  useEffect(() => {
    if (token && currency) {
      dispatch(getPaymentOptions(token, currency, option));
    }
  }, [dispatch, currency, token, option]);

  useEffect(() => {
    if (initializeCollectionCode !== null) {
      if (initializeCollectionCode === 200) {
        history.push(href);
      }
    }
  });

  useEffect(() => {
    if (billingDetails) {
      setCountry(billingDetails.billing_country);
      setAddress1(billingDetails.billing_address);
      setCity(billingDetails.billing_city);
      setRegion(billingDetails.billing_state);
      setZip(billingDetails.billing_zip);
    }
  }, [billingDetails]);

  const onFinishFailed = () => {
    console.error();
  };

  const formatCardNumber = (value) => {
    // Remove all non-digit characters
    const numericValue = value.replace(/\D/g, '');
    // Split the value into groups of 4 digits
    const groups = numericValue.match(/(\d{1,4})/g);
    // Join the groups with a space
    let formattedValue = groups ? groups.join(' ') : '';
    // Truncate the value if it exceeds 16 digits without spaces
    formattedValue = formattedValue.replace(/\s/g, '');
    formattedValue = formattedValue.substr(0, 16);
    // Add spaces back to the truncated value
    formattedValue = formattedValue.replace(/(\d{4})(?=\d)/g, '$1 ');
    return formattedValue;
  };

  const formatName = (e) => {
    const regex = /[^a-zA-Z\s]+/g;
    const value = e.replace(regex, '');
    return value;
  };

  const handleCardholdernameChange = (e) => {
    // accept only letters
    setCardholdername(e.target.value);
  };

  const handleCardnumberChange = (e) => {
    const regex = /\D/g;
    const value = e.target.value.replace(regex, '').substring(0, 16);
    let newValueWithSpaces = '';
    for (let i = 0; i < value.length; i += 1) {
      newValueWithSpaces += value[i];
      if ((i + 1) % 4 === 0 && (i + 1) % 8 !== 0) {
        newValueWithSpaces += ' ';
      }
      if ((i + 1) % 8 === 0) {
        newValueWithSpaces += ' ';
      }
    }

    if ((value).toString().startsWith('4')) {
      setCardIcon(<small><img src={visaImg} alt="" style={{ height: '16px' }} /></small>);
    } else if ((value).toString().startsWith('5') || (value).toString().startsWith('2')) {
      setCardIcon(<small><img src={mCardImg} alt="" style={{ height: '16px' }} /></small>);
    } else {
      setCardIcon(<CreditCardOutlined className="gx-text-muted" />);
    }

    setCardnumber(newValueWithSpaces.trim());
  };

  const handleMonthChange = (month) => {
    setExpiryMonth(month);
  };

  const handleYearChange = (year) => {
    setExpiryYear(year);
  };

  const formatCVV = (e) => {
    const value = e.replace(/\D/g, '').substring(0, 3);
    return value;
  };

  const handleCardCVVChange = (e) => {
    setCardCVV(e.target.value);
  };

  const handleCountryChange = (value) => {
    setCountry(value);
    countries.map((c) => {
      if (c.code === value) {
        if ('states' in c) {
          setStates(c.states);
        } else {
          setStates([]);
        }
      }
      return 0;
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Form
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        name="add-card"
        className="gx-signin-form gx-form-row-0"
      >
        <FormCard>
          <div className="h4 gx-mb-4">Enter card details</div>
          <Form.Item
            name="card_holder"
            className="gx-mx-0"
            rules={
              [
                {
                  required: true,
                  message: 'Card holder name is required',
                },
                {
                  min: 6,
                  message: 'Name must be atleast 6 characters long',
                },
                {
                  whitespace: true,
                },
                {
                  validator: (_, value) => (value.match(/\d+/g) ? Promise.reject(new Error('Invalid card holder name')) : Promise.resolve()),
                },
              ]
            }
            getValueFromEvent={(e) => formatName(e.target.value)}
            hasFeedback
          >
            <Input
              placeholder="Card Holder Name"
              value={cardholdername}
              size="large"
              onChange={handleCardholdernameChange}
            />
          </Form.Item>

          <Form.Item
            className="gx-mx-0"
            name="card_number"
            rules={[
              {
                required: true,
                message: 'Card number is required',
              },
              {

                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  const numericValue = value.replace(/\D/g, '');
                  if (!numericValue.match(/^\d+$/)) {
                    return Promise.reject(new Error('Invalid card number format'));
                  }
                  if (numericValue.length < 16) {
                    return Promise.reject(new Error('Incomplete card number'));
                  }
                  if (!numericValue.startsWith(4) && !numericValue.startsWith(5)) {
                    return Promise.reject(new Error('Unknown card'));
                  }
                  return Promise.resolve();
                },

              },
            ]}
            getValueFromEvent={(e) => formatCardNumber(e.target.value)}
            hasFeedback
          >
            <Input
              placeholder="Card Number"
              value={cardnumber}
              size="large"
              prefix={cardIcon}
              onChange={handleCardnumberChange}
            />
          </Form.Item>

          <Row gutter={24}>

            <Col span={7}>
              <Form.Item
                className="gx-mx-0"
                name="expiry_month"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
                hasFeedback
              >
                <Select
                  onChange={handleMonthChange}
                  placeholder="MM"
                  value={expiryMonth}
                  options={months.map((m) => (
                    {
                      label: m.toString().padStart(2, 0),
                      value: m.toString().padStart(2, 0),
                    }))}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                className="gx-mx-0"
                name="expiry_year"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
                hasFeedback
              >
                <Select
                  onChange={handleYearChange}
                  placeholder="YY"
                  value={expiryYear}
                  options={years.map((y) => (
                    { label: y.toString().slice(-2), value: y.toString().slice(-2) }))}
                />
              </Form.Item>
            </Col>
            <Col span={7} className="gx-ml-auto">
              <Form.Item
                className="gx-mx-0"
                name="cvv"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    min: 3,
                    message: 'Incomplete',
                  },
                ]}
                getValueFromEvent={(e) => formatCVV(e.target.value)}
                hasFeedback
              >
                <Input
                  size="large"
                  placeholder="CVV"
                  value={cardCVV}
                  onChange={handleCardCVVChange}
                />
              </Form.Item>
            </Col>
          </Row>

        </FormCard>

        <Divider />
        <FormCard>
          { billingDetails && !changeBillingAddress ? (
            <>
              <h4>Billing address</h4>
              <div>{billingDetails.billing_address}</div>
              <div>
                {billingDetails.billing_city}
                ,
                {' '}
                {billingDetails.billing_country}
              </div>
              <div>{billingDetails.billing_region}</div>
              <div>{billingDetails.billing_zip}</div>
              <br />
              <div><Button ghost type="primary" size="medium" onClick={() => setChangeBillingAddress(true)}>Change</Button></div>
            </>
          ) : (
            <>
              <div className="h4 gx-mb-3">Enter billing address</div>
              <Form.Item
                className="gx-mx-0"
                initialValue={address1}
                rules={[
                  {
                    required: true,
                    message: 'Billing Address line 1 is required!',
                  },
                  {
                    whitespace: true,
                  },
                ]}
                name="billing_address_1"
                hasFeedback
              >
                <Input
                  size="large"
                  placeholder="Enter address line 1"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                />
              </Form.Item>
              <Row gutter={24}>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    className="gx-mx-0"
                    initialValue={country}
                    rules={[
                      {
                        required: true,
                        message: 'Country is required!',
                      },
                    ]}
                    name="billing_country"
                    hasFeedback
                  >
                    <Select
                      size="large"
                      placeholder="Country"
                      showSearch
                      value={country}
                      onChange={handleCountryChange}
                      options={countries.map((opt) => ({ label: opt.name, value: opt.code }))}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    className="gx-mx-0"
                    initialValue={city}
                    rules={[
                      {
                        required: true,
                        message: 'City/Town is required!',
                      },
                    ]}
                    name="billing_city"
                    hasFeedback
                  >
                    <Input
                      size="large"
                      placeholder="City/Town"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  { states && states.length > 0 ? (
                    <Form.Item
                      className="gx-mx-0"
                      rules={[
                        {
                          required: true,
                          message: 'State/Province is required',
                        },
                      ]}
                      name="region"
                      hasFeedback
                    >
                      <Select
                        size="large"
                        placeholder="State/Province"
                        onChange={(value) => setRegion(value)}
                        showSearch
                        options={states.map((state) => ({ label: state.name, value: state.code }))}
                      />
                    </Form.Item>
                  )
                    : (
                      <Form.Item
                        className="gx-mx-0"
                        initialValue={region}
                        rules={[
                          {
                            required: true,
                            message: 'State/Province is required!',
                          },
                        ]}
                        name="region_city"
                        hasFeedback
                      >
                        <Input
                          size="large"
                          placeholder="State/Province/Region"
                          value={region}
                          onChange={(e) => setRegion(e.target.value)}
                        />
                      </Form.Item>
                    )}
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    className="gx-mx-0"
                    initialValue={zip}
                    rules={[
                      {
                        required: true,
                        message: 'Zip is required',
                      },
                    ]}
                    name="billing_zip"
                    hasFeedback
                  >
                    <Input
                      size="large"
                      placeholder="Zip/Postal Code"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item className="gx-mx-0">
                <div className="gx-mb-2">
                  Save billing address
                  {' '}
                  {' '}
                  <QuestionCircleFilled className="gx-text-primary" onClick={showModal} />
                </div>
                <Switch checked={savebilling} onChange={() => setsavebilling(!savebilling)} checkedChildren="Yes" unCheckedChildren="No" />

              </Form.Item>
            </>
          ) }
        </FormCard>

        <Divider />

        <div style={{ display: 'flex' }}>

          <Button
            type="primary"
            className="gx-mb-0"
            ghost
            onClick={() => history.push('/options')}
          >
            Back
          </Button>
          <div className="gx-ml-auto" />
          <CancelButton />
          <Button
            type="primary"
            className="gx-mb-0"
            htmlType="submit"
          >
            Continue
          </Button>
        </div>
      </Form>

      <Modal
        centered
        visible={isModalOpen}
        footer={null}
      >
        <h4><b>Save Billing Address</b></h4>
        <p>
          Save time by saving your billing address.
          We save these details for future use so you
          do not have to enter them everytime you use this options.
        </p>
        <div className="gx-mt-3 gx-text-right">
          <Button type="primary" onClick={handleCancel}>OK</Button>
        </div>
      </Modal>

      <InfoView />

    </>

  );
};

AddPaymentCard.defaultProps = {
  match: {},
};

AddPaymentCard.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  href: PropTypes.string.isRequired,
};

export default AddPaymentCard;
