import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from 'antd';
import { Icon } from '@ant-design/compatible';

export default function DownloadBtns({ handlePdf, handleXcl }) {
  return (
    <>
      <Button
        title="Download as PDF"
        type="primary"
        icon={<Icon type="download" />}
        color="green"
        onClick={handlePdf}
        ghost
      >
        PDF
      </Button>
      <Button
        title="Download as Excel"
        type="primary"
        icon={<Icon type="download" />}
        onClick={handleXcl}
        ghost
      >
        XLS
      </Button>
    </>
  );
}

DownloadBtns.propTypes = {
  handlePdf: PropTypes.string.isRequired,
  handleXcl: PropTypes.string.isRequired,
};
