import {
  INITIATE_COLLECTION_START,
  INITIATE_COLLECTION_SUCCESS,
  INITIATE_COLLECTION_ERROR,
  CONFIRM_COLLECTION_ERROR,
  CONFIRM_COLLECTION_START,
  CONFIRM_COLLECTION_SUCCESS,
  RESET_TRANSACTION_COLLECTION_STATE,
  RESET_COLLECTION_INITIALIZATION,
} from '../../constants/ActionTypes';
// import encryptStorage from '../../util/encryption';

const INIT_STATE = {
  initializeCollectionCode: null,
  confirmCollectionCode: null,
  transactionData: {},
  response: {},
  message: null,
  reference: null,
  paymentInstructions: null,
  paymentUrl: null,
  error: null,
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INITIATE_COLLECTION_START: {
      return {
        ...state,
        loading: true,
        confirmCollectionCode: null,
      };
    }

    case INITIATE_COLLECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        initializeCollectionCode: action.payload.code,
        transactionData: action.payload.data,
        message: 'Collection successfully initiated',
      };
    }

    case INITIATE_COLLECTION_ERROR: {
      return {
        ...state,
        loading: false,
        message: 'Collection failed to initialize',
        error: action.payload,
      };
    }

    case CONFIRM_COLLECTION_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case CONFIRM_COLLECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        confirmCollectionCode: action.payload.code,
        initializeCollectionCode: null,
        response: action.payload.data,
        message: action.payload.message,
      };
    }

    case RESET_TRANSACTION_COLLECTION_STATE: {
      return {
        ...state,
        confirmationCollectionCode: null,
        initializeCollectionCode: null,
        transactionData: {},
      };
    }

    case CONFIRM_COLLECTION_ERROR: {
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: action.payload,
      };
    }

    case RESET_COLLECTION_INITIALIZATION: {
      return {
        ...state,
        initializeCollectionCode: null,
      };
    }

    default:
      return state;
  }
};
