import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from 'components/common/CircularProgress';
import { getUserTransactions } from '../../redux/actions/Transactions';
import RecentTransactionItem from './RecentTransactionItem';
// import encryptStorage from '../../util/encryption';

const RecentTransactions = () => {
  const dispatch = useDispatch();
  // const token = encryptStorage.getItem('token');
  const {
    recentTransactions, loading, currentPage, pageSize,
  } = useSelector(
    ({ transactions }) => transactions.userTransactions,
  );

  useEffect(() => {
    if (!(recentTransactions)) {
      const defaultPagination = `sort={"created_at": -1}&page=${currentPage}&limit=${pageSize}`;
      dispatch(getUserTransactions(defaultPagination));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      {loading && <CircularProgress className="wallets-loader" />}

      {recentTransactions.length === 0 && <div>No Transactions </div>}

      {recentTransactions.length !== 0 && (
        <div className="gx-module-list gx-mail-list">
          <div
            className="gx-module-content-scroll"
            style={{
              position: 'relative',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
            }}
          >
            <div
              style={{
                position: 'absolute',
                inset: '0px',
                overflow: 'scroll',
                marginRight: '-17px',
                marginBottom: '-17px',
              }}
            >
              {recentTransactions.map((recentTransaction) => (
                // eslint-disable-next-line react/jsx-key
                <RecentTransactionItem transaction={recentTransaction} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RecentTransactions;
