import {
  FETCH_ACTIVITY_LOGS_START,
  FETCH_ACTIVITY_LOGS_SUCCESS,
  FETCH_ACTIVITY_LOGS_ERROR,
  INDEX_PAGE_SIZE_DEFAULT,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  data: [],
  meta: {
    page: 1,
    pageSize: INDEX_PAGE_SIZE_DEFAULT,
    pageTotal: 1,
  },
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ACTIVITY_LOGS_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_ACTIVITY_LOGS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case FETCH_ACTIVITY_LOGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        meta: { ...state.meta, ...action.meta },
      };
    }

    default:
      return state;
  }
};
