import {
  FETCH_WALLET_TRANSACTIONS_INIT,
  FETCH_WALLET_TRANSACTIONS_SUCCESS,
  FETCH_WALLET_TRANSACTIONS_ERROR,
  FETCH_RECENT_TRANSACTIONS_INIT,
  FETCH_RECENT_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_INIT,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_ERROR,
  SET_TRANSACTIONS_PAGE_SIZE,
} from 'constants/ActionTypes';

const INIT_STATE = {
  walletTransactions: {
    data: [],
    loading: false,
    error: null,
    currentPage: 1,
    pageSize: 10,
    totalRecords: 10,
  },
  userTransactions: {
    data: [],
    recentTransactions: [],
    loading: false,
    error: null,
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,
  },
  walletTransfers: {
    data: [],
    loading: false,
    error: null,
    creatingTransfer: false,
    currentPage: 1,
    pageSize: 10,
    totalRecords: 10,
  },
};

const transactions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TRANSACTIONS_PAGE_SIZE: {
      return {
        ...state,
        userTransactions: {
          ...state.userTransactions,
          pageSize: action.limit,
        },
      };
    }

    case FETCH_WALLET_TRANSACTIONS_INIT: {
      return {
        ...state,
        walletTransactions: {
          ...state.walletTransactions,
          loading: true,
        },
      };
    }

    case FETCH_WALLET_TRANSACTIONS_SUCCESS: {
      const { data, currentPage, totalRecords } = action.payload;
      return {
        ...state,
        walletTransactions: {
          ...state.walletTransactions,
          loading: false,
          data,
          currentPage,
          totalRecords,
        },
      };
    }

    case FETCH_WALLET_TRANSACTIONS_ERROR: {
      return {
        ...state,
        walletTransactions: {
          ...state.walletTransactions,
          loading: false,
          error: true,
        },
      };
    }

    case FETCH_RECENT_TRANSACTIONS_SUCCESS: {
      const { data, currentPage, totalRecords } = action.payload;
      return {
        ...state,
        userTransactions: {
          ...state.userTransactions,
          loading: false,
          recentTransactions: data,
          currentPage,
          totalRecords,
        },
      };
    }

    case FETCH_RECENT_TRANSACTIONS_INIT:
    case FETCH_TRANSACTIONS_INIT: {
      return {
        ...state,
        userTransactions: {
          ...state.userTransactions,
          loading: true,
        },
      };
    }

    case FETCH_TRANSACTIONS_SUCCESS: {
      const { data, currentPage, totalRecords } = action.payload;
      return {
        ...state,
        userTransactions: {
          ...state.userTransactions,
          loading: false,
          data,
          currentPage,
          totalRecords,
        },
      };
    }

    case FETCH_TRANSACTIONS_ERROR: {
      return {
        ...state,
        userTransactions: {
          ...state.userTransactions,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default transactions;
