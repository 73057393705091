/* eslint-disable react/prop-types */
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import AppLocale from 'lngProvider';
import { setInitUrl } from 'redux/actions/Auth';
import HealthCheck from 'components/common/health';
import CircularProgress from 'components/common/CircularProgress';
import MainApp from './MainApp';
import SignIn from '../Authentication/SignIn';
import OTP from '../Authentication/OTP';
import Pay from '../../routes/Collections/Pay';
import PageNotFound from '../../PageNotFound';

const RestrictedRoute = ({
  component: Component,
  location,
  token,
  requireOtp,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (requireOtp) {
        return (
          <Redirect
            to={{
              pathname: '/otp',
              state: { from: location },
            }}
          />
        );
      }
      if (token) {
        return <Component {...props} />;
      }

      return (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: location },
          }}
        />
      );
    }}
  />
);

const App = () => {
  const dispatch = useDispatch();
  const { locale } = useSelector(
    ({ settings }) => settings,
  );
  const {
    token, initURL, loadingAuthUser, authUser,
  } = useSelector(
    ({ auth }) => auth,
  );

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
  }, [dispatch, initURL, location.pathname]);

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser === null) {
        history.push('/signin');
      } else {
        history.push('/options');
      }
    }
  }, [authUser, location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        {loadingAuthUser ? (
          <CircularProgress />
        ) : (
          <Switch>
            <Route exact path="/" component={SignIn} />
            <Route path="/health-check" component={HealthCheck} />
            <Route path="/signin" component={SignIn} />
            <Route path="/otp" component={OTP} />
            <Route path="/pay/:id" component={Pay} />
            <RestrictedRoute
              path={`${match.url}`}
              token={token}
              location={location}
              component={MainApp}
            />
            <Route component={PageNotFound} />
          </Switch>
        )}
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
