/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'antd';

import TransactionAmountInput from '../../containers/common/TransactionAmountInput';
import Topbar from '../../containers/Topbar/app';
import RegistrationProgress from '../../components/common/RegistrationProgress/RegistrationProgress';
import ProgressTitle from './ProgressTitle';

const Amount = () => {
  useEffect(() => {
    document.title = 'Mobile Money';
  }, []);

  return (
    <div className="gx-app-wrap">
      <Topbar />

      <div className="complete-registration-content-wrapper">
        <ProgressTitle title="Create you first wallet" />
        <RegistrationProgress
          first="complete"
          second="complete"
          third="active"
          fourth=""
        />
        <div className="h4 gx-text-center gx-my-4">
          Select a currency for your wallet and enter an amount for your first
          deposit
        </div>

        <Row gutter={24} className="gx-mx-0 gx-text-center">
          <Col span={8} style={{ margin: '0 auto' }}>
            <TransactionAmountInput
              href="/confirm-deposit"
              inputPosition="center"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

Amount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
  }).isRequired,
};

export default Amount;
