// Pagination

export const INDEX_PAGE_SIZE_DEFAULT = 10;
export const INDEX_PAGE_SIZE_OPTIONS = [5, 10, 20, 30, 50, 100];

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

// Contact Module const
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const FETCH_CARD = 'FETCH_CARD';
export const ADD_CARD = 'ADD_CARD';
export const SET_COUNT = 'SET_COUNT';

// Auth const
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const ACTIVATE_USER_INIT = 'ACTIVATE_USER_INIT';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const ACTIVATE_USER_ERROR = 'ACTIVATE_USER_ERROR';
export const ACTIVATION_CODE_RECREATE_SUCCESS = 'ACTIVATION_CODE_RECREATE_SUCCESS';
export const SET_FORGOT_PASSWORD_EMAIL = 'SET_FORGOT_PASSWORD_EMAIL';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const RESET_EXPRESS_LOGIN_CODE = 'RESET_EXPRESS_LOGIN_CODE';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'USER_DATA';
export const UPDATE_USER_BILLING_ADDRESS = 'UPDATE_USER_BILLING_ADDRESS';
export const UPDATE_USER_PHONE_NUMBER = 'UPDATE_USER_PHONE_NUMBER';
export const UPDATE_USER_BVN = 'UPDATE_USER_BVN';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const PASSWORD_REQUEST_SUCCESS = 'PASSWORD_RESET_INIT';
export const PASSWORD_RESET_INIT = 'PASSWORD_RESET_INIT';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const EXPRESS_LOGIN_FAILURE = 'EXPRESS_LOGIN_FAILURE';
export const RETURN_TO_EXPRESS_LOGIN = 'RETURN_TO_EXPRESS_LOGIN';
export const RETURN_TO_SELECT_TFA = 'RETURN_TO_SELECT_TFA';
export const PHONENUMBER_SUCCESS = 'PHONENUMBER_SUCCESS';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const LOGIN_VERIFY_OTP_SUCCESS = 'LOGIN_VERIFY_OTP_SUCCESS';
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';
export const COMPLETE_KYC_SUCCESS = 'COMPLETE_KYC_SUCCESS';
export const RESET_STATE = 'RESET_STATE';

// EXPRESS LOGIN
export const EXPRESS_LOGIN_SUCCESS = 'EXPRESS_LOGIN_SUCCESS';
export const REQUEST_EXPRESS_LOGIN_OTP_SUCCESS = 'REQUEST_EXPRESS_LOGIN_OTP_SUCCUESS';

export const FETCH_QRCODE_START = 'FETCH_QRCODE_START';
export const FETCH_QRCODE_SUCCESS = 'FETCH_QRCODE_SUCCESS';
export const FETCH_QRCODE_ERROR = 'FETCH_QRCODE_ERROR';

export const ENABLE_TFA_START = 'ENABLE_TFA_START';
export const ENABLE_TFA_SUCCESS = 'ENABLE_TFA_SUCCESS';
export const ENABLE_TFA_ERROR = 'ENABLE_TFA_ERROR';

export const VALIDATE_TFA_CODE_START = 'VALIDATE_TFA_CODE_START';
export const VALIDATE_TFA_CODE_SUCCESS = 'VALIDATE_TFA_CODE_SUCCESS';
export const VALIDATE_TFA_CODE_ERROR = 'VALIDATE_TFA_CODE_ERROR';

export const REQUIRE_OTP = 'REQUIRE_OTP';
export const REQUIRE_OTP_CANCELLED = 'REQUIRE_OTP_CANCELLED';

// COLLECTIONS
export const INITIATE_COLLECTION_START = 'INITIATE_COLLECTION_START';
export const INITIATE_COLLECTION_SUCCESS = 'INITIATE_COLLECTION_SUCCESS';
export const INITIATE_COLLECTION_ERROR = 'INITIATE_COLLECTION_ERROR';

export const CONFIRM_COLLECTION_ERROR = 'CONFIRM_COLLECTION_ERROR';
export const CONFIRM_COLLECTION_SUCCESS = 'CONFIRM_COLLECTION_SUCCESS';
export const CONFIRM_COLLECTION_START = 'CONFIRM_COLLECTION_START';
export const RESET_TRANSACTION_COLLECTION_STATE = 'RESET_TRANSACTION_COLLECTION_STATE';
export const RESET_COLLECTION_INITIALIZATION = 'RESET_COLLECTION_INITIALIZATION';

// DATA
export const SET_INVOICE_ID = 'SET_INVOICE_ID';
export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
export const RESET_INVOICE_DETAILS = 'RESET_INVOICE_DETAILS';
export const GET_TRANSACTION_STATUS = 'GET_TRANSACTION_STATUS';
export const GET_PAYMENT_PROVIDERS = 'GET_PAYMENT_PROVIDERS';
export const RESET_TRANSACTION_DATA_STATE = 'RESET_TRANSACTION_DATA_STATE';
export const SET_CIPHER = 'SET_CIPHER';
export const UNSET_CIPHER = 'UNSET_CIPHER';
export const SET_PAYMENT_OPTION = 'SET_PAYMENT_OPTION';
export const RESET_PAYMENT_OPTION = 'RESET_PAYMENT_OPTION';
export const SAVE_BILLING_DETAILS = 'SAVE_BILLING_DETAILS';
export const SAVE_PHONE_NUMBER = 'SAVE_PHONE_NUMBER';
export const SAVE_BVN = 'SAVE_BVN';

// PAYOUTS
export const INITIATE_PAYOUT_SUCCESS = 'INITIATE_PAYOUT_SUCCESS';
export const INITIATE_PAYOUT_START = 'INITIATE_PAYOUT_START';
export const INITIATE_PAYOUT_ERROR = 'INITIATE_PAYOUT_ERROR';

export const CONFIRM_PAYOUT_START = 'CONFIRM_PAYOUT_START';
export const CONFIRM_PAYOUT_SUCCESS = 'CONFIRM_PAYOUT_SUCCESS';
export const CONFIRM_PAYOUT_ERROR = 'CONFIRM_PAYOUT_ERROR';

// TRANSFERS
export const FETCH_TRANSFER_START = 'FETCH_TRANSFER_START';
export const FETCH_TRANSFER_SUCCESS = 'FETCH_TRANSFER_SUCCESS';
export const FETCH_TRANSFER_ERROR = 'FETCH_TRANSFER_ERROR';

// TRANSACTIONS
export const FETCH_WALLET_TRANSACTIONS_INIT = 'FETCH_WALLET_TRANSACTIONS_INIT';
export const FETCH_WALLET_TRANSACTIONS_SUCCESS = 'FETCH_WALLET_TRANSACTIONS_SUCCESS';
export const FETCH_WALLET_TRANSACTIONS_ERROR = 'FETCH_WALLET_TRANSACTIONS_ERROR';
export const FETCH_RECENT_TRANSACTIONS_INIT = 'FETCH_RECENT_TRANSACTIONS_INIT';
export const FETCH_RECENT_TRANSACTIONS_SUCCESS = 'FETCH_RECENT_TRANSACTIONS_SUCCESS';
export const FETCH_RECENT_TRANSACTIONS_ERROR = 'FETCH_RECENT_TRANSACTIONS_ERROR';
export const FETCH_TRANSACTIONS_INIT = 'FETCH_TRANSACTIONS_INIT';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'FETCH_TRANSACTIONS_ERROR';
export const SET_TRANSACTIONS_PAGE_SIZE = 'SET_TRANSACTIONS_PAGE_SIZE';

// USERS
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

// CURRENCIES
export const GET_SUPPORTED_CURRENCIES = 'GET_SUPPORTED_CURRENCIES';
export const FETCH_CURRENCY_START = 'FETCH_CURRENCY_START';
export const FETCH_CURRENCY_SUCCESS = 'FETCH_CURRENCY_SUCCESS';
export const FETCH_CURRENCY_ERROR = 'FETCH_CURRENCY_ERROR';

export const FETCH_CURRENCIES_START = 'FETCH_CURRENCIES_START';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export const FETCH_CURRENCIES_ERROR = 'FETCH_CURRENCIES_ERROR';

// PAYMENT METHODS
export const FETCH_PAYMENT_METHOD_START = 'FETCH_PAYMENT_METHOD_START';
export const FETCH_PAYMENT_METHOD_SUCCESS = 'FETCH_PAYMENT_METHOD_SUCCESS';
export const FETCH_PAYMENT_METHOD_ERROR = 'FETCH_PAYMENT_METHOD_ERROR';

export const FETCH_PAYMENT_METHODS_START = 'FETCH_PAYMENT_METHODS_START';
export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
export const FETCH_PAYMENT_METHODS_ERROR = 'FETCH_PAYMENT_METHODS_ERROR';

// PROCESSORS
export const FETCH_PROCESSOR_START = 'FETCH_PROCESSOR_START';
export const FETCH_PROCESSOR_SUCCESS = 'FETCH_PROCESSOR_SUCCESS';
export const FETCH_PROCESSOR_ERROR = 'FETCH_PROCESSOR_ERROR';

// WALLETS
export const FETCH_WALLETS_INIT = 'FETCH_WALLETS_INIT';
export const FETCH_WALLETS_SUCCESS = 'FETCH_WALLETS_SUCCESS';
export const FETCH_WALLETS_ERROR = 'FETCH_WALLETS_ERROR';

export const SET_SELECTED_WALLET = 'SET_SELECTED_WALLET';
export const SET_ACTIVE_WALLET = 'SET_ACTIVE_WALLET';

export const CREATE_USER_WALLET_INIT = 'CREATE_USER_WALLET_INIT';
export const CREATE_USER_WALLET_SUCCESS = 'CREATE_USER_WALLET_SUCCESS';
export const CREATE_USER_WALLET_ERROR = 'CREATE_USER_WALLET_ERROR';

// PROVIDERS
export const FETCH_PROVIDER_START = 'FETCH_PROVIDER_START';
export const FETCH_PROVIDER_SUCCESS = 'FETCH_PROVIDER_SUCCESS';
export const FETCH_PROVIDER_ERROR = 'FETCH_PROVIDER_ERROR';

// COUNTRIES
export const FETCH_COUNTRIES_INIT = 'FETCH_COUNTRIES_INIT';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES_ERROR';

// STATS
export const FETCH_STATS_START = 'FETCH_STATS_START';
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS';
export const FETCH_STATS_ERROR = 'FETCH_STATS_ERROR';
export const STATS_CURRENCY_CHANGED = 'STATS_CURRENCY_CHANGED';
export const STATS_STATUS_CHANGED = 'STATS_STATUS_CHANGED';

// ACTIVITY_LOGS
export const FETCH_ACTIVITY_LOGS_START = 'FETCH_ACTIVITY_LOGS_START';
export const FETCH_ACTIVITY_LOGS_SUCCESS = 'FETCH_ACTIVITY_LOGS_SUCCESS';
export const FETCH_ACTIVITY_LOGS_ERROR = 'FETCH_ACTIVITY_LOGS_ERROR';
