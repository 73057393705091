/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Button,
  Form,
  Typography,
  Row,
  Col,
  Input,
  Switch,
  Modal,
  Radio,
} from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import Topbar from '../../containers/Topbar/app';
import RegistrationProgress from '../../components/common/RegistrationProgress/RegistrationProgress';
import { initializeCollection } from '../../redux/actions/Collection';
import encryptStorage from '../../util/encryption';
import { getPaymentOptions } from '../../redux/actions/Data';
import {
  SET_PAYMENT_OPTION,
  SAVE_BVN,
  UPDATE_USER_BVN,
} from '../../constants/ActionTypes';
import FormCard from '../../components/common/FormCard';
import InfoView from '../../components/common/InfoView';
import PayCard from '../../containers/common/PayCard';
import CancelButton from '../../components/Widget/CancelButton';
import { stripTrailingSlash } from '../../util/helpers';

const Bank = (props) => {
  const [BVN, setBVN] = useState('');
  const [saveBVN, setSaveBVN] = useState(true);
  const [addBVN, setAddBVN] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { invoiceDetails } = useSelector(({ data }) => data);
  const { getProviderDetails } = useSelector(({ data }) => data);
  const { bvn } = useSelector(({ auth }) => auth.authUser);
  const { amount } = invoiceDetails;
  const dispatch = useDispatch();
  const { currency } = invoiceDetails;
  const option = 'BANK';
  const allInput = amount !== 0;
  const { history } = props;
  const token = encryptStorage.getItem('token');
  const { initializeCollectionCode } = useSelector(({ collection }) => collection);
  const baseUrl = (process.env.REACT_APP_BASE_URL).toString();

  useEffect(() => {
    document.title = 'Complete payment with bank';
  }, []);

  // Sets payment option
  useEffect(() => {
    dispatch({ type: SET_PAYMENT_OPTION, payload: 'BANK' });
  }, [dispatch]);

  // @returns payment options for a given currency
  useEffect(() => {
    if (token && currency) {
      dispatch(getPaymentOptions(token, currency, option));
    }
  }, [dispatch, currency, token, option]);

  const onFinish = (values) => {
    const collection = {
      customer_reference: 'auto',
      invoice_id: invoiceDetails.id,
      transaction_origin: 'APP',
      transaction_method: 'BANK',
      currency: invoiceDetails.currency,
      amount: invoiceDetails.amount,
      provider_code: getProviderDetails.provider_code,
      description: `QWAAP Wallet deposit for ${invoiceDetails.id}`,
      redirect_url: `${stripTrailingSlash(baseUrl)}/check-status`,
    };

    if (invoiceDetails.currency === 'NGN') {
      dispatch({ type: SAVE_BVN, payload: saveBVN });
      if (!bvn || values.bvn !== bvn) {
        dispatch({ type: UPDATE_USER_BVN, payload: BVN });
      }
      collection.account_number = BVN;
    }

    dispatch(initializeCollection(collection, token));
  };

  useEffect(() => {
    if (initializeCollectionCode !== null) {
      if (initializeCollectionCode === 200) {
        history.push('/confirm');
      }
    }
  });

  // Formats BVN input accepting only upto 11 digits
  const formatBVN = (e) => {
    const value = e.replace(/\D/g, '').substring(0, 11);
    return value;
  };

  return (
    <div>
      <Topbar />
      <div style={{ margin: '3% auto' }}>
        <Typography.Title
          level={4}
          style={{ textAlign: 'center', fontWeight: 400 }}
        >
          Bank Transfer
        </Typography.Title>

        <RegistrationProgress
          first="complete"
          second="active"
          third=""
          fourth=""
        />

        <Row gutter={24} className="gx-mx-0">
          <Col xl={8} lg={12} md={16} sm={24} xs={24} className="gx-mx-auto gx-my-5">
            <PayCard
              amount={(invoiceDetails.amount).toString()}
              currency={(invoiceDetails.currency).toString()}
            />
            <br />

            <Form
              initialValues={{ remember: true }}
              name="fundbybank"
              className="gx-signin-form gx-form-row-0"
              onFinish={onFinish}
            >
              <FormCard>
                {currency === 'NGN' && (
                  <>
                    {!addBVN && bvn ? (
                      <>
                        <div className="h5 gx-font-weight-bold gx-mb-2">Select your Bank Verification Number (BVN)</div>
                        <Form.Item
                          name="bvn"
                          rules={[{
                            required: true,
                            message: 'Please select a BVN',
                          }]}
                          className="gx-mx-0 gx-mb-0"
                        >
                          <Radio.Group
                            onChange={(e) => setBVN(e.target.value)}
                            value={bvn}
                          >
                            <Radio value={bvn}>{bvn}</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div className="gx-mt-1 gx-text-right"><Button type="primary" ghost size="medium" onClick={() => setAddBVN(!addBVN)}>Use another BVN</Button></div>
                      </>
                    ) : (
                      <>
                        <Form.Item style={{ marginBottom: 0, fontWeight: 600 }} className="gx-mx-0">
                          <Typography.Text>Enter Bank Verification Number (BVN)</Typography.Text>
                        </Form.Item>
                        <Form.Item
                          name="bvn"
                          initialValue={BVN}
                          className="gx-mx-0 gx-my-0"
                          onChange={(e) => setBVN(e.target.value)}
                          rules={[
                            {
                              required: true,
                              message: 'BVN is required',
                            },
                            {
                              min: 11,
                              message: 'Incomplete BVN',
                            },
                          ]}
                          getValueFromEvent={(e) => formatBVN(e.target.value)}
                          hasFeedback
                        >
                          <Input size="large" placeholder="Enter BVN" value={Number(BVN)} />
                        </Form.Item>
                        <Form.Item className="gx-mx-0 gx-mb-0">
                          <div className="gx-mb-2 gx-mt-3">
                            Save BVN
                            {' '}
                            <QuestionCircleFilled className="gx-text-primary" onClick={() => setShowModal(true)} />
                          </div>
                          <Switch
                            checked={saveBVN}
                            onChange={() => setSaveBVN(!saveBVN)}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                          />
                        </Form.Item>
                        {bvn && (
                          <div className="gx-mt-1 gx-text-right"><Button type="primary" ghost size="medium" onClick={() => setAddBVN(!addBVN)}>Use saved BVN</Button></div>
                        )}
                      </>
                    )}
                  </>
                )}
              </FormCard>
              <FormCard>
                Continue for instructions on how to complete your bank transaction.
              </FormCard>

              <Form.Item className="gx-mx-0 gx-my-0">
                <div className="gx-flex-row">
                  <Button type="primary" ghost onClick={() => history.go(-1)}>
                    Back
                  </Button>
                  <div className="gx-ml-auto" />
                  <CancelButton />
                  <Button
                    type="primary"
                    className={`gx-mb-0 ${allInput ? 'enabled' : ' disabled'}`}
                    htmlType={`${allInput ? 'submit' : 'button'}`}
                  >
                    Continue
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Modal
          centered
          visible={showModal}
          footer={null}
          onCancel={() => setShowModal(false)}
        >
          <h4><b>Save Phone Number</b></h4>
          <p>
            Save time by saving your BVN.
            We save your BVN for future use so you
            do not have to enter it everytime you use this payment option.
          </p>
          <div className="gx-mt-3 gx-text-right">
            <Button type="primary" onClick={() => setShowModal(false)}>OK</Button>
          </div>
        </Modal>
        <InfoView />
      </div>
    </div>
  );
};

Bank.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
  }).isRequired,
};

export default Bank;
