import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Dashboard from 'routes/Dashboard';
import Transactions from 'routes/Transactions';
import VerifyPhoneNumber from './Collections/VerifyPhoneNumber';
import Options from './Collections/Options';
import Momo from './Collections/Momo';
import CardP from './Collections/Card';
import Crypto from './Collections/Crypto';
import Bank from './Collections/Bank';
import Amount from './Collections/Amount';
import Confirm from './Collections/Confirm';
import PageNotFound from '../PageNotFound';
import Signout from './signout';
import Check from './Collections/Check';

const App = () => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/transactions" component={Transactions} />
      <Route
        path="/verify-phone-number"
        component={VerifyPhoneNumber}
      />
      <Route path="/options" component={Options} />
      <Route path="/momo" component={Momo} />
      <Route path="/card" component={CardP} />
      <Route path="/bank" component={Bank} />
      <Route path="/crypto" component={Crypto} />
      <Route path="/amount" component={Amount} />
      <Route path="/confirm" component={Confirm} />
      <Route path="/signout" component={Signout} />
      <Route path="/check-status/:ref" component={Check} />
      <Route component={PageNotFound} />
    </Switch>
  </div>
);

App.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

App.defaultProps = {
  match: {},
};

export default App;
