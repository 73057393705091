import {
  FETCH_PAYMENT_METHOD_START,
  FETCH_PAYMENT_METHOD_SUCCESS,
  FETCH_PAYMENT_METHOD_ERROR,
  FETCH_PAYMENT_METHODS_START,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_ERROR,
  INDEX_PAGE_SIZE_DEFAULT,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  data: [],
  meta: {
    page: 1,
    pageSize: INDEX_PAGE_SIZE_DEFAULT,
    pageTotal: 1,
  },
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHOD_START:
    case FETCH_PAYMENT_METHODS_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_PAYMENT_METHOD_ERROR:
    case FETCH_PAYMENT_METHODS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case FETCH_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        loading: false,
        item: action.payload,
      };
    }

    case FETCH_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        meta: { ...state.meta, ...action.meta },
      };
    }

    default:
      return state;
  }
};
