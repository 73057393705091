import React from 'react';
import { Card } from 'antd';
import { PropTypes } from 'prop-types';

const cardStyle = {
  // borderColor: 'rgba(0,0,0,0.25)',
  backgroundColor: '#f6f6f6',
};

const FormCard = ({ children }) => (
  <Card style={cardStyle} bordered>
    {children}
  </Card>
);

FormCard.defaultProps = {
  children: [],
};

FormCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default FormCard;
