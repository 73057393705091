/* eslint-disable linebreak-style */
import React from 'react';
import { Row, Col } from 'antd';
import { PropTypes } from 'prop-types';
import ProgressItem from './ProgressItem';

function RegistrationProgress(props) {
  const {
    first, second, third, fourth,
  } = props;
  return (
    <Row gutter={24} className="gx-mx-0">
      <Col xl={14} lg={14} md={20} sm={24} xs={24} className="gx-mx-auto">
        <div className="progress-wrap">
          <ProgressItem name="Options" status={first} />
          <ProgressItem name="Initiate" status={second} />
          <ProgressItem name="Confirm" status={third} />
          <ProgressItem name="Complete" status={fourth} />
        </div>
      </Col>
    </Row>
  );
}

RegistrationProgress.propTypes = {
  first: PropTypes.string.isRequired,
  second: PropTypes.string.isRequired,
  third: PropTypes.string.isRequired,
  fourth: PropTypes.string.isRequired,
};

export default RegistrationProgress;
