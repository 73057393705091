import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import RegistrationProgress from '../../components/common/RegistrationProgress/RegistrationProgress';
import ProgressTitle from './ProgressTitle';
import Topbar from '../../containers/Topbar/app';
import ConfirmTransaction from '../../containers/common/ConfirmTransaction';

const Confirm = () => {
  useEffect(() => {
    document.title = 'Confirm Funds';
  }, []);

  return (
    <div className="gx-app-wrap">
      <Topbar />

      <div className="complete-registration-content-wrapper">
        <ProgressTitle title="Transaction Details" />
        <RegistrationProgress
          first="complete"
          second="complete"
          third="active"
          fourth=""
        />
        <div className="h4 gx-text-center gx-my-4">
          Review your transaction details
        </div>

        <Row gutter={24} className="gx-mx-0 gx-pb-5">
          <Col xl={8} lg={12} md={16} sm={24} xs={24} className="gx-mx-auto">
            <ConfirmTransaction />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Confirm;
