import React from 'react';
import { PropTypes } from 'prop-types';

import loader from '../../assets/images/qwaaploader.svg';

const CircularProgress = ({ className }) => (
  <div className={`loader ${className}`}>
    <img src={loader} alt="loader" style={{ width: '40px', height: 'auto' }} />
  </div>
);

CircularProgress.propTypes = {
  className: PropTypes.string,
};

CircularProgress.defaultProps = {
  className: '',
};

export default CircularProgress;
